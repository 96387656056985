import { Injectable } from '@angular/core'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import { AddressService } from './address.service'
import * as AddressActions from './address.action'
import { mergeMap, map, catchError, filter } from 'rxjs/operators'
import { of } from 'rxjs'
import { Store } from '@ngrx/store'
import { State } from '../app.state'
import { getAddresses } from './address.reducers'

@Injectable()
export class AddressEffects {
	constructor(
		private actions$: Actions,
		private addressService: AddressService,
		private store: Store<State>
	) {}

	getAddresses$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AddressActions.getAddresses),
			concatLatestFrom(() => this.store.select(getAddresses)),
			// Only load addresses if the array has at least one address
			filter(([_, addresses]) => !Boolean(addresses.length)),
			map(() => AddressActions.loadAddresses())
		)
	})

	loadAddresses$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AddressActions.loadAddresses),
			mergeMap(() =>
				this.addressService.getAddresses().pipe(
					map(addresses => AddressActions.loadAddressesSuccess({ addresses })),
					catchError(error => of(AddressActions.loadAddressesFailure({ error })))
				)
			)
		)
	})
}
