<header class="header">
	<button class="header__button" (click)="openModal()">
		<img class="header__icon" src="assets/images/menu.svg" />
		<div class="header__inner-container">
			<div class="header__circle" *ngIf="hasPaymentReady$ | async">
				<span class="header__circle__notification">1</span>
			</div>
		</div>
	</button>
</header>

<app-modal [id]="modalHeaderId" top="0" bottom="0" animation="horizontal-i">
	<app-modal-header [id]="modalHeaderId" [hasPaymentReady]="hasPaymentReady$ | async"></app-modal-header>
</app-modal>
