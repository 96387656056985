import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { HttpService } from '../../shared/services/http.service'
import { CreatePaymentDto, OrderFromHistory } from './order'

export const DEFAULT_LIMIT = 10

@Injectable({
	providedIn: 'root',
})
export class OrderService {
	constructor(private http: HttpService) {}

	getHistory(filterHistoryDto?: FilterHistoryQueryDto): Observable<OrderFromHistory[]> {
		const query: FilterHistoryQueryDto = {
			...filterHistoryDto,
		}
		return this.http.get<OrderFromHistory[]>('api/v1/orders/history', query)
	}

	createPayment(paymentDto: CreatePaymentDto) {
		return this.http.post('api/v2/payments', paymentDto)
	}

	cancelOrder(orderId: string) {
		return this.http.delete(`api/v1/orders/${orderId}`)
	}

	getOrderById(orderId: string) {
		return this.http.get(`api/v1/orders/${orderId}`)
	}

	getOrdersPaymentReady(clientId: string) {
		return this.http.get(`api/v1/orders/payment/ready/${clientId}`)
	}
}

export interface FilterHistoryQueryDto {
	clientId?: string
	partnerId?: string
	cpf?: string
	intent?: string
	startDate?: Date
	endDate?: Date
	skip?: number
	limit?: number
}
