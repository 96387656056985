import { Pipe, PipeTransform } from '@angular/core'

const TAGME_IMAGE_SERVICE = 'https://tagmeimages.azureedge.net'

@Pipe({ name: 'optimizeCardSrc' })
export class OptimizeCardSrc implements PipeTransform {
	private width = 340 * 2
	private height = 136 * 2
	transform(
		url: string | undefined,
		output: 'jpg' | 'avif' | 'webp' = 'jpg',
		width?: number,
		height?: number
	) {
		if (!url) {
			return ''
		}
		this.width = width ?? this.width
		this.height = height ?? this.height

		return `${TAGME_IMAGE_SERVICE}/?w=${this.width}&h=${this.height}&output=${output}&dpr=1&url=${url} 1x, ${TAGME_IMAGE_SERVICE}/?w=${this.width}&h=${this.height}&output=${output}&dpr=2&url=${url} 2x`
	}
}

@Pipe({ name: 'optimizeCard' })
export class OptimizeCard implements PipeTransform {
	private width = 340 * 2
	private height = 136 * 2
	transform(
		url: string | undefined,
		output: 'jpg' | 'avif' | 'webp' = 'jpg',
		width?: number,
		height?: number
	) {
		if (!url) {
			return ''
		}
		this.width = width ?? this.width
		this.height = height ?? this.height

		return `${TAGME_IMAGE_SERVICE}/?w=${this.width}&h=${this.height}&output=${output}&dpr=1&url=${url} 1x`
	}
}
