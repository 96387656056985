import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store'
import { CheckInActions } from './check-in.actions'
import { AvailabilityCheckin } from './checkin'

export type Status = 'idle' | 'pending' | 'failure' | 'success'

export interface CheckInState {
	availabilities: AvailabilityCheckin | null
	statusAvailability: Status | 'unavailable'
	errorAvailability: string | null
	statusConfirm: Status
	errorConfirm: string | null
	orderId: string | null
	checkinDate: {
		day: string
		time: string
	} | null
}

const initialState: CheckInState = {
	availabilities: null,
	statusAvailability: 'idle',
	errorAvailability: null,
	statusConfirm: 'idle',
	errorConfirm: null,
	orderId: null,
	checkinDate: null,
}

const checkinFeatureState = createFeatureSelector<CheckInState>('checkin')

export const getOrderId = createSelector(checkinFeatureState, state => state.orderId)
export const getErrorAvailability = createSelector(checkinFeatureState, state => state.errorAvailability)
export const getStatusAvailability = createSelector(checkinFeatureState, state => state.statusAvailability)
export const getStatusConfirm = createSelector(checkinFeatureState, state => state.statusConfirm)
export const getCheckInDate = createSelector(checkinFeatureState, state => state.checkinDate)
export const getErrorConfirm = createSelector(checkinFeatureState, state => state.errorConfirm)
export const getAvailabilities = createSelector(checkinFeatureState, state => state.availabilities)

export const checkinReducer = createReducer<CheckInState>(
	initialState,
	on(CheckInActions.loadAvailabilities, (state): CheckInState => {
		return {
			...state,
			statusAvailability: 'pending',
		}
	}),
	on(CheckInActions.updateAvailabilities, (state, action): CheckInState => {
		return {
			...state,
			availabilities: action.availabilities,
		}
	}),
	on(CheckInActions.loadAvailabilitiesSuccess, (state, action): CheckInState => {
		return {
			...state,
			availabilities: action.availabilities,
			orderId: action.orderId,
			errorAvailability: null,
			statusAvailability: 'success',
		}
	}),
	on(CheckInActions.loadAvailabilitiesFailure, (state, action): CheckInState => {
		return {
			...state,
			errorAvailability: action.error,
			statusAvailability: 'failure',
		}
	}),
	on(CheckInActions.checkinConfirm, (state, action): CheckInState => {
		return {
			...state,
			checkinDate: {
				day: action.day,
				time: action.time,
			},
			statusConfirm: 'pending',
			errorConfirm: null,
		}
	}),
	on(CheckInActions.checkinConfirmSuccess, (state): CheckInState => {
		return {
			...state,
			errorConfirm: null,
			statusConfirm: 'success',
		}
	}),
	on(CheckInActions.checkinConfirmFailure, (state, action): CheckInState => {
		return {
			...state,
			errorConfirm: action.error,
			statusConfirm: 'failure',
		}
	}),
	on(CheckInActions.checkinUnavailable, (state, action): CheckInState => {
		return {
			...state,
			statusAvailability: 'unavailable',
			errorAvailability: action.error,
			availabilities: action.availabilities,
		}
	})
)
