import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
	name: 'replace',
})
export class ReplacePipe implements PipeTransform {
	transform(value: string | null, regexValue: string, replaceValue: string): string {
		if (!value) {
			return ''
		}
		const regex = new RegExp(regexValue, 'g')
		return value.replace(regex, replaceValue)
	}
}
