<div id="cvv" class="modalContent">
	<div class="modalContent__header">
		<h2 class="modalContent__title">O que é o código CVV?</h2>
		<div class="modalContent__close" (click)="closeModal()">
			<i class="modalContent__close__icon fa fa-close"></i>
		</div>
	</div>
	<div class="modalContent__body">
		<div class="modalContent__line">
			<img src="./../../../../assets/images/cvv.png" alt="" class="modalContent__img" />
		</div>
		<p class="modalContent__text">São os 3 números que aparecem na parte de trás do seu cartão.</p>
	</div>
	<div class="modalContent__footer">
		<button class="modalContent__button button" (click)="closeModal()">OK</button>
	</div>
</div>
