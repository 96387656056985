import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ModalService } from '../modal.service'

type TemplateModalCancel = 'reservation' | 'wallet'

@Component({
	selector: 'app-modal-cancel [template]',
	templateUrl: './modal-cancel.component.html',
	styleUrls: ['./../modal-content.component.scss', './modal-cancel.component.scss'],
})
export class ModalCancelComponent implements OnInit {
	@Input() id: string = ''
	@Input() template: TemplateModalCancel = 'reservation'
	@Input() loading: boolean | null = false
	@Output() cancelSubmitted = new EventEmitter<void>()
	constructor(public modalService: ModalService) {}

	templates: Record<TemplateModalCancel, any> = {
		reservation: 'cancelar a reserva?',
		wallet: 'excluir o cartão?',
	}

	ngOnInit(): void {}

	closeModal() {
		this.modalService.close(this.id)
	}

	cancel() {
		this.cancelSubmitted.emit()
	}
}
