import { Component, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { Store } from '@ngrx/store'
import { combineLatest, filter, map, tap } from 'rxjs'
import { ModalService } from 'src/app/component/modal/modal.service'
import { PREFIX_TITLE } from 'src/app/shared/helpers/prefix-title'
import { State } from 'src/app/state/app.state'
import { getClient } from 'src/app/state/client/client.reducers'
import { getPaymentOrder, getSelectedOrder } from 'src/app/state/order/order.reducers'

@Component({
	selector: 'app-payment-confirm',
	templateUrl: './payment-confirm.component.html',
	styleUrls: ['./payment-confirm.component.scss'],
})
export class PaymentConfirmComponent implements OnInit {
	selectedOrder$ = this.store.select(getSelectedOrder).pipe(
		filter(value => Boolean(value)),
		tap(order => {
			this.title.setTitle(`${PREFIX_TITLE} - ${order?.venue.name} - Pagamento realizado com sucesso!`)
		})
	)

	clientState$ = this.store.select(getClient)
	paymentOrder$ = this.store.select(getPaymentOrder)

	vm$ = combineLatest([this.selectedOrder$, this.clientState$, this.paymentOrder$]).pipe(
		map(([selectedOrder, clientState, paymentOrder]) => ({ selectedOrder, clientState, paymentOrder }))
	)

	constructor(public modalService: ModalService, private store: Store<State>, private title: Title) {}

	ngOnInit(): void {}
}
