<div id="registerCard" class="modalContent">
	<div class="modalContent__header">
		<h2 class="modalContent__title">Cadastrar cartão</h2>
	</div>
	<div class="modalContent__body">
		<p class="modalContent__text">
			Você ainda não tem um cartão cadastrado para pagar a conta nos restaurantes pela Esfera e ganhar
			pontos.
		</p>
		<div class="modalContent__buttons">
			<button class="modalContent__button button" [routerLink]="['/carteira', 'adicionar-cartao']">
				Cadastrar cartão
			</button>
			<button class="modalContent__buttonGhost buttonGhost" (click)="close()">
				Não desejo ganhar pontos
			</button>
		</div>
	</div>
</div>
