import { Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'

import { HttpService } from '../../shared/services/http.service'
import { ResponseAvailabilityCheckin } from './checkin'

@Injectable({
	providedIn: 'root',
})
export class CheckInService {
	constructor(private http: HttpService) {}

	getAvailabilities(checkAvailabilityDto: CheckAvailabilityDto) {
		const body: CheckAvailabilityDto = {
			partnerId: environment.partnerID,
			...checkAvailabilityDto,
		}
		return this.http.post<ResponseAvailabilityCheckin>('api/v1/orders/checkAvailability', body)
	}

	confirmCheckin(orderId: string, confirmCheckinDto: ConfirmCheckinDto) {
		return this.http.post(`api/v1/orders/confirm/${orderId}`, confirmCheckinDto)
	}
}
export interface CheckAvailabilityDto {
	partnerId?: string
	clientId: string
	venueId: string
	categoryId: string
	intent: 'checkin'
}

export interface ConfirmCheckinDto {
	reservationDay: string // YYY-MM-DD
	reservationTime: string // HH:MM
	clientId: string
	partySize: number
}
