<!-- referente ao Figma 236
    é a parte da jornada em que se coloca o valor da conta, a Taxa de Serviço do garçom,
    tem o total a ser pago, os pontos ganhos e segue para pagamento -->

<!-- <app-header></app-header> -->
<div class="page" *ngIf="selectedOrder$ | async as selectedOrder">
	<div class="page__header"></div>
	<div class="page__body">
		<div class="wrapper">
			<section class="pBill__item">
				<div class="pBill__item__left">
					<p class="pBill__item__title">{{ selectedOrder.venue.name }}</p>
					<p class="pBill__item__text">{{ selectedOrder.venue.address | formatAddress }}</p>
					<div class="pBill__item__bottom">
						<div class="pBill__tab">
							<p class="pBill__tab__item">
								Reserva de mesa às {{ selectedOrder.reservationTime }}
							</p>
						</div>
					</div>
				</div>
				<div class="pBill__item__right">
					<div class="pBill__date">
						<p class="pBill__date__day">{{ selectedOrder.reservationDay | date : 'dd' }}</p>
						<p class="pBill__date__month">
							{{ selectedOrder.reservationDay | date : 'MMM' | replace : '[.]' : ' ' }}
						</p>
					</div>
				</div>
			</section>

			<section class="pBill__benefit">
				<!-- BENEFÍCIOS -->
				<ng-container class="venue__benefit" *ngIf="selectedGeneric$ | async as generic">
					<app-benefit *ngIf="generic" [value]="generic.name" text="generic"></app-benefit>
					<br /><br />
				</ng-container>
				<ng-container class="venue__benefit" *ngIf="selectedPoints$ | async as benefit">
					<app-benefit [value]="benefit.value"></app-benefit>
				</ng-container>
			</section>
		</div>
		<section class="pBill__background">
			<form class="pBill__form" [formGroup]="form">
				<input
					id="bill"
					class="pBill__input"
					currencyMask
					[options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
					placeholder="Digite o valor a pagar"
					type="text"
					formControlName="totalValue"
					inputmode="numeric"
				/>
				<div style="height: 30px"></div>

				<app-info title="Valor da conta" [text]="bill$ | async | currency : 'BRL'"></app-info>

				<ng-container *ngIf="!removeGratuityValue">
					<app-divisor top="30px" bottom="30px"></app-divisor>
					<app-info
						title="Taxa de Serviço do garçom"
						[text]="billTip$ | async | currency : 'BRL'"
						subTitle="Incluir Taxa de Serviço adicional"
						template="tip"
						(clickedIcon)="openModaTip()"
					></app-info>
				</ng-container>
				<app-modal [id]="modalsId.modalTip" top="35px">
					<app-modal-tip
						[id]="modalsId.modalTip"
						[billTip]="billTip$ | async"
						[suggestedTip]="suggestedTip"
						(changedTip)="changeTip($event)"
					></app-modal-tip>
				</app-modal>

				<app-divisor top="12px" bottom="30px"></app-divisor>

				<ng-container *ngIf="points$ | async as points; else noPoints">
					<ng-container *ngIf="campaignService.promo$ | async as promo">
						<ng-container *ngIf="promo.isPromo">
							<app-info
								title="Você receberá"
								[text]="points * 2 + ' pontos'"
								template="points"
							></app-info>
						</ng-container>
						<ng-container *ngIf="!promo.isPromo">
							<app-info
								title="Você receberá"
								[text]="points + ' pontos'"
								template="points"
							></app-info>
						</ng-container>
					</ng-container>
				</ng-container>
				<ng-template #noPoints>
					<app-info title="Você receberá" text="" template="points"></app-info>
				</ng-template>

				<app-divisor top="12px" bottom="30px"></app-divisor>

				<app-info
					template="totalSum"
					title="Valor total a pagar"
					[text]="totalValue$ | async | currency : 'BRL'"
				></app-info>
			</form>
		</section>
	</div>
	<div class="page__footer">
		<div class="pBill__wrapper wrapper">
			<button
				class="pBill__button button"
				[routerLink]="['/pagamento-cartao', selectedOrder._id]"
				[disabled]="form.invalid"
				[class.button--disable]="form.invalid"
			>
				Ir para pagamento
			</button>
		</div>
	</div>
</div>

<app-modal [id]="modalsId.modalRegisterCard" top="25%" [preventOutsideClickClose]="true">
	<app-modal-register-card [id]="modalsId.modalRegisterCard"></app-modal-register-card>
</app-modal>
