<div class="page">
	<div class="page__body">
		<img src="./../../../assets/images/indique.png" style="margin: 24px 24px 36px 24px" />
		<div class="page__body__box">
			<i class="fa fa-check-inverse iconTop"></i>

			<div class="boxText">
				<p class="boxText__title">Mensagem enviada com sucesso</p>
				<p class="boxText__subTitle">
					Obrigado por indicar um restaurante. Entraremos em contato em breve.
				</p>
			</div>
		</div>
	</div>
	<div class="page__footer">
		<button (click)="goTo()" class="page__footer__btn" type="submit">Ir para restaurantes</button>
	</div>
</div>
