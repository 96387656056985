import { Component, Input } from '@angular/core'
import { Router } from '@angular/router'
import { ModalService } from '../../modal/modal.service'

@Component({
	selector: 'app-modal-header',
	templateUrl: './modal-header.component.html',
	styleUrls: ['./modal-header.component.scss'],
})
export class ModalHeaderComponent {
	@Input() id!: string
	@Input() hasPaymentReady!: boolean | null

	constructor(private modalService: ModalService, private router: Router) {}

	closeModal() {
		this.modalService.close(this.id)
	}

	goTo(url: string) {
		this.router.navigate([url])
		this.closeModal()
	}
}
