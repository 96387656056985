import { Component, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { PREFIX_TITLE } from 'src/app/shared/helpers/prefix-title'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'

@Component({
	selector: 'app-recommendation-success',
	templateUrl: './recommendation-success.component.html',
	styleUrls: ['./recommendation-success.component.scss'],
})
export class RecommendationSuccessComponent implements OnInit {
	pageTitle = 'Indicação um restaurante'

	constructor(private title: Title, private router: Router) {}

	ngOnInit(): void {
		this.title.setTitle(`${PREFIX_TITLE} - ${this.pageTitle}`)
	}

	goTo() {
		this.router.navigate(['restaurantes'])
	}
}
