import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
	name: 'formatPartysize',
})
export class FormatPartysize implements PipeTransform {
	transform(partySize: number) {
		return partySize <= 1 ? `${partySize} pessoa` : `${partySize} pessoas`
	}
}
