<div class="page">
	<div class="page__header">
		<app-header></app-header>

		<h2 class="title">{{ pageTitle }}</h2>
	</div>
	<form [formGroup]="responseForm" (ngSubmit)="onSubmit()">
		<div class="page__body">
			<div class="page__body__radio">
				<div class="custom-radio">
					<input
						type="radio"
						id="checkbox1"
						name="checkboxes"
						value="Cliente"
						formControlName="checkboxes"
						class="custom-radio-input"
					/>
					<label for="checkbox1" class="custom-radio-label">Sou cliente</label>
				</div>
				<div class="custom-radio">
					<input
						type="radio"
						id="checkbox2"
						name="checkboxes"
						value="Responsável"
						formControlName="checkboxes"
						class="custom-radio-input"
					/>
					<label for="checkbox2" class="custom-radio-label">Sou Responsável</label>
				</div>
			</div>

			<div class="page__body__user">
				<p class="page__body__user__title">Nome</p>
				<p class="page__body__user__subTitle">{{ clientInfo.name }}</p>
				<p class="page__body__user__title">Email</p>
				<p class="page__body__user__subTitle">{{ clientInfo.email }}</p>
			</div>

			<input
				class="custom-input"
				placeholder="Nome do Restaurante"
				type="text"
				id="text"
				formControlName="venueName"
				style="width: 100%"
			/>
			<div style="position: relative">
				<div class="page__body__boxInput">
					<input
						class="custom-input"
						placeholder="Cidade"
						type="text"
						id="text"
						formControlName="city"
						style="width: 60%"
					/>
					<div class="select-container" style="width: 40%">
						<select style="width: 100%" id="select" formControlName="state" class="custom-select">
							<option value="">Estado</option>
							<option *ngFor="let uf of ufs" [value]="uf">{{ uf }}</option>
						</select>
					</div>
					<i class="fa fa-arrow custom-arrow"></i>
				</div>
			</div>

			<input
				class="custom-input"
				placeholder="Bairro (opcional)"
				type="text"
				id="text"
				formControlName="neighborhood"
				style="width: 100%"
			/>
		</div>
		<div class="page__footer">
			<button
				class="page__footer__btn"
				[ngClass]="{ 'disabled-btn': responseForm.invalid }"
				[disabled]="responseForm.invalid"
				type="submit"
			>
				Enviar
			</button>
		</div>
	</form>
</div>

<app-modal [id]="modalErrorId" top="25%" [preventOutsideClickClose]="true">
	<app-modal-failure
		[id]="modalErrorId"
		template="somethingWentWrong"
		message="Ocorreu um erro inesperado. Por favor tente novamente."
	></app-modal-failure>
</app-modal>
