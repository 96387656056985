<div class="modalContent">
	<div class="modalContent__header">
		<h2 class="modalContent__title">
			Gostaria de tornar o cartão de últimos digitos ({{ lastFourDigits }}) como prinicipal?
		</h2>
		<div class="modalContent__close" (click)="closeModal()">
			<i class="modalContent__close__icon fa fa-close"></i>
		</div>
	</div>
	<div class="modalContent__body">
		<div class="modalContent__buttons">
			<button class="modalContent__buttonGhost buttonGhost" (click)="updateMainCard()">Sim</button>
			<button class="modalContent__button button" (click)="closeModal()">Não</button>
		</div>
	</div>
</div>
