<div class="benefit">
	<div class="benefit__left">
		<i class="benefit__icon fa fa-check"></i>
		<p class="benefit__text" [ngClass]="chooseSize">
			<ng-container [ngSwitch]="text">
				<ng-container *ngSwitchCase="'default'" [ngTemplateOutlet]="default"></ng-container>
				<ng-container *ngSwitchCase="'short'" [ngTemplateOutlet]="short"></ng-container>
				<ng-container *ngSwitchCase="'generic'" [ngTemplateOutlet]="generic"></ng-container>
			</ng-container>
		</p>
	</div>
</div>

<ng-template #default>
	<ng-container *ngIf="campaignService.promo$ | async as promo">
		<ng-container *ngIf="promo.isPromo">
			Ganhe
			<span class="benefit__text--strong" [ngClass]="{ from: promo.isPromo }">{{ value }} pontos</span>
			<!--Promo em dobro-->
			<span class="benefit__text--strong to">{{ doubleValue }} pontos</span>
			a cada real
			<br />
			pagando a conta pelo App Esfera
		</ng-container>
		<ng-container *ngIf="!promo.isPromo">
			Ganhe
			<span class="benefit__text--strong">{{ value }} pontos</span>
			<br />
			pagando a conta pelo App Esfera
		</ng-container>
	</ng-container>
</ng-template>
<!-- esse texto acima aparecerá APENAS quando estiver na página de PAGAMENTO -->
<ng-template #short>
	<ng-container *ngIf="campaignService.promo$ | async as promo">
		<ng-container *ngIf="promo.isPromo">
			Ganhe
			<span class="benefit__text--strong" [ngClass]="{ from: promo.isPromo }">{{ value }} pontos</span>
			<!--Promo em dobro-->
			<span class="benefit__text--strong to">{{ doubleValue }} pontos</span>
			a cada real gasto na conta
		</ng-container>
		<ng-container *ngIf="!promo.isPromo">
			Ganhe
			<span class="benefit__text--strong" [ngClass]="{ from: promo.isPromo }">{{ value }} pontos</span>
			a cada real gasto na conta
		</ng-container>
	</ng-container>
</ng-template>

<ng-template #generic>
	<ng-container>
		Ganhe
		<span class="benefit__text--strong"> {{ value }}</span>
	</ng-container>
</ng-template>
