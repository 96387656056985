import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { combineLatest, fromEvent, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from '../../../environments/environment'

import { HttpService } from '../../shared/services/http.service'
import { State } from '../app.state'
import { Venue } from './venue'
import { isAllLoaded, isLoadingMoreVenues } from './venue.reducers'

export const DEFAULT_LIMIT = 10

@Injectable({
	providedIn: 'root',
})
export class VenueService {
	scrollAction$: Observable<Event> = fromEvent(document, 'scroll')
	isAllLoaded$: Observable<boolean> = this.store.select(isAllLoaded)
	isLoadingMoreVenues$ = this.store.select(isLoadingMoreVenues)

	loadMoreVenue$: Observable<boolean> = combineLatest([
		this.scrollAction$,
		this.isAllLoaded$,
		this.isLoadingMoreVenues$,
	]).pipe(
		map(([_, allLoaded, isLoadingMoreVenues]) => {
			if (!allLoaded && !isLoadingMoreVenues) {
				return (
					document.documentElement.scrollTop >=
					0.8 * document.documentElement.scrollHeight - document.documentElement.clientHeight
				)
			}
			return false
		})
	)

	constructor(private http: HttpService, private store: Store<State>) {}

	getVenues(filterVenuesDto?: FilterVenuesDto): Observable<Venue[]> {
		const body: FilterVenuesDto = {
			partner: environment.partnerID,
			venuesAlreadyInView: [],
			limit: DEFAULT_LIMIT,
			...filterVenuesDto,
		}
		return this.http.post<Venue[]>('api/v1/venues/filter', body)
	}

	getVenueId(venueId: string, findOneVenueDto?: FindOneVenueDto): Observable<Venue> {
		const body: FindOneVenueDto = {
			partner: environment.partnerID,
			...findOneVenueDto,
		}
		return this.http.post<Venue>(`api/v1/venues/${venueId}`, body)
	}
}

export interface FindOneVenueDto {
	partner?: string
	categoryId?: string
	clientId?: string
}

export interface FilterVenuesDto {
	partner?: string
	clientId?: string
	cousine?: string
	name?: string
	address?: {
		state?: string
		city?: string
		neighborhood?: string
		address?: string
		location?: { latitude: number; longitude: number; maxDistance: number }
	}
	type?: Array<string>
	categoryId?: string
	skip?: number
	limit?: number
	venuesAlreadyInView?: Array<string>
	favOnly?: boolean
}
