import { Component, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { ActivatedRoute } from '@angular/router'
import { Store } from '@ngrx/store'
import { filter, tap } from 'rxjs'
import { PREFIX_TITLE } from 'src/app/shared/helpers/prefix-title'
import { State } from 'src/app/state/app.state'
import { getOrderById } from 'src/app/state/order/order.reducers'
import * as OrderActions from '../../../state/order/order.actions'

@Component({
	templateUrl: './reservation-cancel-sucess.component.html',
	styleUrls: ['./reservation-cancel-sucess.component.scss'],
})
export class ReservationCancelSuccessComponent implements OnInit {
	orderById$ = this.store.select(getOrderById).pipe(
		filter(value => Boolean(value)),
		tap(order =>
			this.title.setTitle(
				`${PREFIX_TITLE} - ${order?.venue?.name} - CANCELAMENTO realizado com sucesso!`
			)
		)
	)

	constructor(private route: ActivatedRoute, private store: Store<State>, private title: Title) {}

	ngOnInit(): void {
		const orderId = this.route.snapshot.paramMap.get('orderId')
		orderId && this.store.dispatch(OrderActions.getOrderById({ orderId }))
	}
}
