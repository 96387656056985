<div class="page">
	<div class="page__header">
		<section class="rFail__header">
			<div class="rFail__wrapper wrapper">
				<p class="rFail__subtitle subtitle">
					Reserva não pode<br />
					ser realizada.
				</p>
			</div>
		</section>
	</div>
	<div class="page__body">
		<div class="rFail__wrapper wrapper">
			<section class="rFail__section section">
				<!-- <p class="rFail__text">
					Infelizmente deu algum problema na sua reserva para o horário/dia que você deseja.
				</p> -->
				<p class="rFail__text" *ngIf="error$ | async as error">
					{{ error }}
				</p>
			</section>
		</div>
	</div>
	<div class="page__footer">
		<div class="rFail__wrapper wrapper">
			<button class="rFail__button button" (click)="goBack()">Fazer uma nova reserva</button>
			<button class="rFail__buttonGhost buttonGhost" [routerLink]="['/restaurantes']">
				Listar restaurantes
			</button>
		</div>
	</div>
</div>
