import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { Store } from '@ngrx/store'
import { combineLatest, filter, map, Subscription, take, tap } from 'rxjs'
import { CreditCardHelper } from 'src/app/shared/helpers/credit-card.helper'
import { State } from 'src/app/state/app.state'
import { getCards, getSelectedCard } from 'src/app/state/client/client.reducers'
import * as ClientActions from '../../../state/client/client.actions'
import { ModalService } from '../modal.service'

@Component({
	selector: 'app-modal-change-card',
	templateUrl: './modal-change-card.component.html',
	styleUrls: ['./modal-change-card.component.scss', './../modal-content.component.scss'],
})
export class ModalChangeCardComponent implements OnInit, OnDestroy {
	form!: FormGroup
	cards$ = this.store.select(getCards)
	@Input() id!: string

	subs = new Subscription()
	constructor(private modalService: ModalService, private fb: FormBuilder, private store: Store<State>) {}

	chooseBrand = CreditCardHelper.chooseBrand

	ngOnInit(): void {
		this.form = this.fb.group({
			card: [],
		})
		this.subs.add(this.updateSelectedCard)
		this.subs.add(this.addDefaultValue)
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe()
	}

	private get updateSelectedCard() {
		return this.form.get('card')?.valueChanges.subscribe(card => {
			this.store.dispatch(ClientActions.setSelectedCard({ card: card }))
			this.closeModal()
		})
	}

	private get addDefaultValue() {
		return this.store
			.select(getSelectedCard)
			.pipe(take(1))
			.subscribe(selectedCard => {
				this.form.get('card')?.setValue(selectedCard)
			})
	}

	closeModal() {
		this.modalService.close(this.id)
	}
}
