import { Component, ElementRef, Input, OnInit } from '@angular/core'
import { ModalService } from '../modal.service'

@Component({
	selector: 'app-modal-about',
	templateUrl: './modal-about.component.html',
	styleUrls: ['./modal-about.component.scss', './../modal-content.component.scss'],
})
export class ModalAboutComponent implements OnInit {
	@Input() id!: string
	@Input() name!: string | undefined
	@Input() descriptionLong!: string | undefined
	constructor(public modalService: ModalService) {}

	ngOnInit(): void {}

	closeModal() {
		this.modalService.close(this.id)
	}
}
