type BRAND_PAGARME =
	| 'elo'
	| 'mastercard'
	| 'visa'
	| 'diners'
	| 'amex'
	| 'hipercard'
	| 'vr'
	| 'sodexo'
	| 'alelo'

function chooseBrand(value: string | undefined) {
	if (!value) {
		return
	}
	const valueLowerCAse = value.toLowerCase() as BRAND_PAGARME
	const chooseBrand: Record<BRAND_PAGARME, { src: string; alt: string }> = {
		alelo: {
			src: 'card-alelo.svg',
			alt: 'Alelo',
		},
		amex: {
			src: 'card-amex.svg',
			alt: 'Amex',
		},
		diners: {
			src: 'card-diners.svg',
			alt: 'Diners',
		},
		elo: {
			src: 'card-elo.svg',
			alt: 'Elo',
		},
		hipercard: {
			src: 'card-hipercard.svg',
			alt: 'Hipercard',
		},
		mastercard: {
			src: 'card-mastercard.svg',
			alt: 'Mastercard',
		},
		sodexo: {
			src: 'card-sodexo.svg',
			alt: 'Sodexo',
		},
		visa: {
			src: 'card-visa.svg',
			alt: 'Visa',
		},
		vr: {
			src: 'card-hipercard.svg',
			alt: 'Hipercard',
		},
	}
	return chooseBrand[valueLowerCAse]
}

function formatCPF(cpf: string) {
	return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
}

export const CreditCardHelper = {
	chooseBrand,
	formatCPF,
}
