<section class="slide" *ngIf="slides && slides.length">
	<div class="slide__wrapper wrapper s-type-1" role="slider">
		<div class="slide__control"></div>

		<swiper
			#swiper
			[config]="config"
			(swiper)="onSwiper($event)"
			(slideChange)="onSlideChange()"
			class="slide__content"
		>
			<ng-template swiperSlide *ngFor="let item of slides">
				<li class="slide__content__item" *ngIf="item.click && !item.router && !item.href">
					<picture style="width: 100%">
						<source
							[srcset]="item.imageDesktop | absolutePath | optimizeCardSrc : 'jpg' : 375 : 240"
						/>
						<img
							class="slide__content__img"
							[alt]="item.alt"
							[src]="item.imageDesktop | absolutePath | optimizeCard : 'jpg' : 375 : 240"
							loading="lazy"
						/>
					</picture>
				</li>
				<li class="slide__content__item" *ngIf="!item.click && !item.router && !item.href">
					<picture style="width: 100%">
						<source
							[srcset]="item.imageDesktop | absolutePath | optimizeCardSrc : 'jpg' : 375 : 240"
						/>
						<img
							class="slide__content__img"
							[alt]="item.alt"
							[src]="item.imageDesktop | absolutePath | optimizeCard : 'jpg' : 375 : 240"
							loading="lazy"
						/>
					</picture>
				</li>
				<li class="slide__content__item" *ngIf="item.href && !item.router && !item.click">
					<picture style="width: 100%">
						<source
							[srcset]="item.imageDesktop | absolutePath | optimizeCardSrc : 'jpg' : 375 : 240"
						/>
						<img
							class="slide__content__img"
							[alt]="item.alt"
							[src]="item.imageDesktop | absolutePath | optimizeCard : 'jpg' : 375 : 240"
							loading="lazy"
						/>
					</picture>
				</li>
				<li class="slide__content__item" *ngIf="item.router && !item.href && !item.click">
					<picture style="width: 100%">
						<source
							[srcset]="item.imageDesktop | absolutePath | optimizeCardSrc : 'jpg' : 375 : 240"
						/>
						<img
							class="slide__content__img"
							[alt]="item.alt"
							[src]="item.imageDesktop | absolutePath | optimizeCard : 'jpg' : 375 : 240"
							loading="lazy"
						/>
					</picture>
				</li>
			</ng-template>
		</swiper>
	</div>
</section>
