import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { ofType } from '@ngrx/effects'
import { ActionsSubject, Store } from '@ngrx/store'
import { Subscription, tap } from 'rxjs'
import { ModalService } from 'src/app/component/modal/modal.service'
import { CreditCardHelper } from 'src/app/shared/helpers/credit-card.helper'
import { PREFIX_TITLE } from 'src/app/shared/helpers/prefix-title'
import { State } from 'src/app/state/app.state'
import { getCards, getError } from 'src/app/state/client/client.reducers'
import * as ClientActions from '../../state/client/client.actions'

@Component({
	templateUrl: './wallet.component.html',
	styleUrls: ['./wallet.component.scss'],
})
export class WalletComponent implements OnInit, OnDestroy, AfterViewInit {
	cards$ = this.store.select(getCards)
	isOpenCards!: { lastFourDigits: string; enabled: boolean }[] | undefined
	modals = {
		cancel: 'modalWalletCancelId',
		mainCard: 'modalWalletMainCardId',
		failure: 'modalFailureId',
	} as const
	error$ = this.store.select(getError)

	subs = new Subscription()
	constructor(
		private store: Store<State>,
		private actions$: ActionsSubject,
		private modalService: ModalService,
		private title: Title
	) {}

	imageBrand:
		| { lastFourDigits: string; imageBrand: { src: string; alt: string } | undefined }[]
		| undefined = undefined

	ngOnInit(): void {
		this.title.setTitle(`${PREFIX_TITLE} - Carteira`)
		this.subs.add(
			this.cards$
				.pipe(
					tap(cards => {
						this.imageBrand = cards?.map(card => ({
							lastFourDigits: card.lastFourDigits,
							imageBrand: CreditCardHelper.chooseBrand(card.brand),
						}))
					}),
					tap(cards => {
						this.isOpenCards = cards?.map(card => ({
							lastFourDigits: card.lastFourDigits,
							enabled: false,
						}))
					})
				)
				.subscribe()
		)
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe()
	}

	ngAfterViewInit(): void {
		this.subs.add(
			this.actions$.pipe(ofType(ClientActions.updateMainCardError)).subscribe(() => {
				this.modalService.open(this.modals.failure)
			})
		)
	}

	chooseBrand(lastFourDigits: string) {
		if (!this.imageBrand) {
			return
		}
		const foundCard = this.imageBrand.find(card => card.lastFourDigits === lastFourDigits)
		return foundCard?.imageBrand
	}

	getIsOpenCard(lastFourDigits: string) {
		if (!this.isOpenCards) {
			return
		}
		const foundCard = this.isOpenCards.find(card => card.lastFourDigits === lastFourDigits)
		return foundCard?.enabled
	}

	toggleOpenCard(lastFourDigits: string) {
		if (!this.isOpenCards) {
			return
		}
		this.isOpenCards = this.isOpenCards.map(card => ({
			...card,
			enabled: card.enabled ? false : card.lastFourDigits === lastFourDigits,
		}))
	}

	openModalCancel(id: string) {
		this.modalService.open(`${this.modals.cancel}_${id}`)
	}

	openModalMainCard(id: string) {
		this.modalService.open(`${this.modals.mainCard}_${id}`)
	}

	removeCard(lastFourDigits: string) {
		this.store.dispatch(ClientActions.removeCard({ lastFourDigits }))
		this.modalService.close(`${this.modals.cancel}_${lastFourDigits}`)
	}

	updateMainCard(lastFourDigits: string) {
		this.store.dispatch(ClientActions.updateMainCard({ lastFourDigits }))
		this.modalService.close(`${this.modals.mainCard}_${lastFourDigits}`)
	}
}
