<div class="page">
	<div class="page__header">
		<section class="rCancel__header">
			<div class="rCancel__wrapper wrapper">
				<p class="rCancel__subtitle subtitle">
					O cancelamento da sua reserva<br />
					não pôde ser realizado.
				</p>
			</div>
		</section>
	</div>
	<div class="page__body" *ngIf="vm$ | async as vm">
		<div class="rCancel__wrapper wrapper">
			<section class="rCancel__section section">
				<div class="rCancel__date">
					<p class="rCancel__date__day">{{ vm.confirmReservation.reservationDay | date : 'd' }}</p>
					<p class="rCancel__date__month">
						{{ vm.confirmReservation.reservationDay | date : 'MMM' | replace : '[.]' : '' }}
					</p>
				</div>

				<section class="rCancel__ticket">
					<app-ticket label="Restaurante" [data]="vm.venue?.name"></app-ticket>
					<app-ticket
						label="Mesa para"
						[data]="vm.confirmReservation.partySize | formatPartysize"
					></app-ticket>

					<app-ticket label="Ambiente" [data]="vm.confirmReservation.sectionLabel"></app-ticket>
					<app-ticket label="Horário" [data]="vm.confirmReservation.reservationTime"></app-ticket>
				</section>
			</section>
		</div>
	</div>
	<div class="page__footer email">
		<div class="bar"></div>
		<div class="rCancel__wrapper wrapper">
			<div class="rCancel__top">
				<div class="rCancel__ass">
					Abraços, <br />
					Equipe Esfera
				</div>
				<ul class="rCancel__list">
					<li class="rCancel__item">
						<a href="" class="rCancel__link" target="_blank">
							<i class="rCancel__icon fa fa-facebook"></i>
						</a>
					</li>
					<li class="rCancel__item">
						<a href="" class="rCancel__link" target="_blank">
							<i class="rCancel__icon fa fa-instagram"></i>
						</a>
					</li>
					<li class="rCancel__item">
						<a href="" class="rCancel__link" target="_blank">
							<i class="rCancel__icon fa fa-youtube"></i>
						</a>
					</li>
					<li class="rCancel__item">
						<a href="" class="rCancel__link" target="_blank">
							<i class="rCancel__icon fa fa-linkedin"></i>
						</a>
					</li>
				</ul>
				<div class="rCancel__help">
					Ficou com alguma dúvida?<br />
					Confira nossa
					<a href="" class="rCancel__help__link">Central de Ajuda</a>
				</div>
			</div>
			<hr />
			<p class="rCancel__date">
				Esfera Fidelidade S.A. - CNPJ:31.595.265/0001-03<br />
				Avenida Presidente Juscelino Kubitschek, São Paulo, SP, 04543-011 Brasil
			</p>
			<p class="rCancel__company">Uma empresa <span class="rCancel__logo fa fa-santander"></span></p>
		</div>
	</div>
</div>
