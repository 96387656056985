<div class="page">
	<div class="page__header">
		<div class="order__wrapper wrapper">
			<section class="order__section section">
				<app-header></app-header>
				<h2 class="order__subtitle subtitle">Minhas reservas</h2>
				<!-- CARTEIRA -->
				<a class="action" [routerLink]="['/carteira']">
					<i class="action__icon fa fa-wallet"></i>
					<p class="action__text">Carteira</p>
				</a>
			</section>
		</div>
	</div>

	<div class="page__body">
		<div class="order__wrapper wrapper">
			<app-spinner *ngIf="loadingOrderHistory$ | async"></app-spinner>
			<ng-container *ngIf="orders$ | async as orders">
				<ul class="order__list">
					<app-order-card *ngFor="let order of orders" [order]="order"></app-order-card>
				</ul>

				<!-- SEM RESERVAS -->
				<div class="order__box" *ngIf="orders?.length === 0">
					<app-box type="advice" text="adviceNoReservation"></app-box>
				</div>
			</ng-container>

			<!-- Esta é a mensagem de falha e o botão é para recarregar a página -->
			<!-- <div class="order__fail">
				<button class="order__fail__button">
					<i class="order__fail__icon fa fa-refresh"></i>
				</button>
				<p class="order__fail__text">
					Tivemos um problema ao carregar os dados.<br />
					Clique no botão acima para recarregar.
				</p>
			</div> -->
		</div>
	</div>

	<div class="page__footer">
		<div class="order__wrapper wrapper">
			<!-- ao clicar neste vai para a página carteira -->
			<button class="order__buttonLight buttonLight" [routerLink]="['/carteira']">
				Ver meus cartões
			</button>
		</div>
	</div>
</div>

<app-modal [id]="errorModalId" top="25%" [preventOutsideClickClose]="true">
	<app-modal-failure
		[id]="errorModalId"
		template="somethingWentWrong"
		*ngIf="error$ | async as error"
		[message]="error"
	></app-modal-failure>
</app-modal>
