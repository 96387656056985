import { Injectable } from '@angular/core'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import { mergeMap, map, switchMap, catchError } from 'rxjs/operators'
import { of } from 'rxjs'
import { ReservationService, ConfirmReservationDto } from './reservation.service'
import * as AvailabilityActions from './reservation.actions'
import { Store } from '@ngrx/store'
import { State } from '../app.state'
import { getConfirmReservation, getOrderId } from './reservation.reducers'
import { getClient, getClientId } from '../client/client.reducers'

@Injectable()
export class ReservationEffects {
	constructor(
		private actions$: Actions,
		private availabilityService: ReservationService,
		private store: Store<State>
	) {}

	loadAvailabilities$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AvailabilityActions.loadAvailabilities),
			concatLatestFrom(() => this.store.select(getClient)),
			mergeMap(([action, clientState]) => {
				return this.availabilityService
					.getAvailabilities({
						venueId: action.venueId,
						clientId: clientState.client._id,
						categoryId: clientState.selectedCard.categoryId,
					})
					.pipe(
						switchMap(response => {
							return [
								AvailabilityActions.loadAvailabilitiesSuccess({
									availabilities: response.availabilities,
								}),
								AvailabilityActions.setOrderId({ orderId: response.order._id }),
							]
						}),
						catchError(error =>
							of(AvailabilityActions.loadAvailabilitiesFailure({ error: error.error.message }))
						)
					)
			})
		)
	})

	confirmReservation$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AvailabilityActions.confirmReservation),
			concatLatestFrom(() => [
				this.store.select(getOrderId),
				this.store.select(getConfirmReservation),
				this.store.select(getClientId),
			]),
			mergeMap(([_, orderId, confirmReservationState, clientId]) =>
				this.availabilityService
					.confirmReservation(
						orderId as string,
						{ ...confirmReservationState, clientId } as ConfirmReservationDto
					)
					.pipe(
						map(() => AvailabilityActions.confirmReservationSuccess()),
						catchError(error =>
							of(AvailabilityActions.confirmReservationFailure({ error: error.error.message }))
						)
					)
			)
		)
	})
}
