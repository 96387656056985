<form [formGroup]="form">
	<div class="page">
		<div class="page__body">
			<div class="pCard__wrapper wrapper" *ngIf="vm$ | async as vm">
				<section class="pCard__ticket">
					<app-ticket
						label="Valor a pagar"
						[data]="vm.paymentOrder?.totalValue?.toString() | currency : 'BRL'"
					></app-ticket>
					<app-ticket label="Restaurante" [data]="vm.selectedOrder?.venue?.name"></app-ticket>
					<app-ticket
						label="Data"
						[data]="vm.selectedOrder?.reservationDay | date : 'dd/MM/YYYY'"
					></app-ticket>
				</section>

				<section class="pCard__card card">
					<div class="card__left">
						<p class="card__text">Valor a pagar</p>
						<p class="card__value">{{ vm.paymentOrder?.totalValue | currency : 'BRL' }}</p>
					</div>
					<div class="card__right">
						<div class="card__line">
							<div class="card__cardNumber">
								<div class="card__cardFlag">
									<img
										*ngIf="chooseBrand(vm.clientState.selectedCard.brand) as imageBrand"
										src="{{ '/assets/images/' + imageBrand.src }}"
										[alt]="imageBrand.alt"
									/>
								</div>
								<i class="card__icon fa fa-bullets"></i>
								<p class="card__cardNumber__theLast">
									{{ vm.clientState.selectedCard.lastFourDigits }}
								</p>
							</div>
						</div>
						<!-- <p class="card__name">
							{{ vm.clientState.client.name }} {{ vm.clientState.client.lastName }}
						</p> -->
						<div class="card__cvv">
							<p class="card__cvv__text">Digite o CVV</p>
							<input
								type="text"
								class="card__cvv__input"
								placeholder="000"
								mask="000||0000"
								formControlName="dynamicCVV"
								inputmode="numeric"
							/>
						</div>
						<span class="card__change" (click)="openModal()">Trocar cartão</span>
					</div>

					<app-modal [id]="modalChangeCardId" bottom="0" animation="vertical-i">
						<app-modal-change-card [id]="modalChangeCardId"></app-modal-change-card>
					</app-modal>
				</section>
			</div>
		</div>
		<div class="page__footer">
			<div class="pCard__wrapper wrapper">
				<ng-container *ngIf="{ loading: loadingPayment$ | async } as obj">
					<button
						class="pCard__button button"
						(click)="createPayment()"
						[disabled]="form.invalid || obj.loading"
						[class.button--disable]="form.invalid || obj.loading"
						type="submit"
					>
						Confirmar pagamento
						<app-spinner class="loading" *ngIf="obj.loading"></app-spinner>
					</button>
				</ng-container>
			</div>
		</div>
	</div>
</form>

<app-modal [id]="modalFailureId" top="25%" [preventOutsideClickClose]="true">
	<app-modal-failure
		[id]="modalFailureId"
		template="somethingWentWrong"
		*ngIf="errorFromPayment$ | async as error"
		[message]="error"
	></app-modal-failure>
</app-modal>
