import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { HttpService } from 'src/app/shared/services/http.service'
import {
	AddressViaCepResponse,
	CreateCardDto,
	CreatePaymentDto,
	EncryptCardDataDto,
} from './add-card.interface'

@Injectable({
	providedIn: 'root',
})
export class AddCardService {
	private viaCep = 'https://viacep.com.br/ws'

	constructor(private httpChannels: HttpService, private httpClient: HttpClient) {}

	createCardToken(createCard: CreateCardDto) {
		return this.httpChannels.post('api/v1/payments/create-card-token', createCard)
	}

	createPayment(paymentDto: CreatePaymentDto) {
		return this.httpChannels.post('api/v1/payments', paymentDto)
	}

	encrypt(body: EncryptCardDataDto) {
		return this.httpChannels.post<{ cardData: string }>('api/v1/payments/encrypt', body)
	}

	getCEP(cep: string) {
		return this.httpClient.get<AddressViaCepResponse>(`${this.viaCep}/${cep}/json`)
	}
}
