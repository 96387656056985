import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
	name: 'formatPhone',
})
export class FormatPhone implements PipeTransform {
	transform(phone: string) {
		const onlyNumbers = phone.replace(/\D/g, '')
		return onlyNumbers.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3')
	}
}
