<div class="journey">
	<div class="journey__background">
		<i class="journey__background__icon"></i>
		<i class="journey__background__icon"></i>
	</div>

	<div class="journey__header">
		<div class="journey__wrapper wrapper">
			<h1 class="journey__header__title">
				Gastronomia<br />
				Esfera<span class="journey__header__span">_</span>
			</h1>
		</div>
	</div>

	<div class="journey__body">
		<div class="journey__wrapper wrapper">
			<p class="journey__body__title">
				<span class="journey__body__title--strong">1ª fase</span><br />
				Jornada "Reserva de Mesa + Pagamento"
			</p>

			<div class="journey__body__actions">
				<button class="journey__body__actions__button button">
					Reservar uma mesa (cartão não-cadastrado)
				</button>
				<button class="journey__body__actions__button button">
					Reservar uma mesa (cartão cadastrado)
				</button>
				<button class="journey__body__actions__button button">
					Pagar a conta (cartão não-cadastrado)
				</button>
				<button class="journey__body__actions__button button">
					Pagar a conta (cartão cadastrado)
				</button>
			</div>
		</div>
	</div>

	<div class="journey__footer">
		<div class="journey__wrapper wrapper">
			<p class="journey__footer__text">powered by</p>
			<i class="journey__footer__icon fa fa-tagme"></i>
		</div>
	</div>
</div>
