import { createAction, props } from '@ngrx/store'
import { AvailabilityCheckin } from './checkin'

const loadAvailabilities = createAction('[Check-In Page] Load Availabilities', props<{ venueId: string }>())
const loadAvailabilitiesSuccess = createAction(
	'[Channels API] Load Availabilities Check-In Success',
	props<{ availabilities: AvailabilityCheckin; orderId: string }>()
)

const loadAvailabilitiesFailure = createAction(
	'[Channels API] Load Availabilities Check-In Fail',
	props<{ error: string }>()
)

const checkinConfirm = createAction(
	'[Check-In Page] Check-In Confirmation',
	props<{ day: string; time: string; partySize: number }>() // day: YYYY-MM-DD time: HH:mm
)
const checkinConfirmSuccess = createAction('[Channels API] Check-In Confirm Success')

const checkinUnavailable = createAction(
	'[Channels API] Check-In Confirm Unavailable',
	props<{ availabilities: AvailabilityCheckin; error: string }>()
)

const checkinConfirmFailure = createAction(
	'[Channels API] Check-In Confirm Failure',
	props<{ error: string }>()
)

const updateAvailabilities = createAction(
	'[Channels API] Update Availabilities Check-In',
	props<{ availabilities: AvailabilityCheckin; orderId: string }>()
)

export const CheckInActions = {
	loadAvailabilities,
	loadAvailabilitiesSuccess,
	loadAvailabilitiesFailure,
	checkinConfirm,
	checkinConfirmSuccess,
	checkinConfirmFailure,
	updateAvailabilities,
	checkinUnavailable,
}
