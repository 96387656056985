import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { Actions, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { interval, map, Observable, of, startWith, Subscription, tap } from 'rxjs'
import { State } from 'src/app/state/app.state'
import { ORDER_PAYMENT_STATUS, OrderFromHistory } from 'src/app/state/order/order'
import { loadingCancel } from 'src/app/state/order/order.reducers'
import * as OrderActions from '../../state/order/order.actions'
import { ModalService } from '../modal/modal.service'

@Component({
	selector: 'app-order-card',
	templateUrl: './order-card.component.html',
	styleUrls: ['./order-card.component.scss'],
})
export class OrderComponent implements OnInit {
	@Input() order: OrderFromHistory | null = null

	wasPaid!: boolean
	hasVenueConfig!: boolean
	nowEverySecond$ = interval(1000).pipe(
		startWith(0),
		map(() => Date.now())
	)

	canPay$!: Observable<boolean>

	paymentBlocked$!: Observable<boolean>
	// Can cancel means show the button cancel reservation
	// The user can cancel if now is less than reservationTime
	canCancel$!: Observable<boolean>

	loadingCancel$ = this.store.select(loadingCancel)
	modalCancelId!: string

	subs = new Subscription()
	constructor(
		public modalService: ModalService,
		private router: Router,
		private store: Store<State>,
		private actions$: Actions
	) {}

	ngOnInit(): void {
		this.modalCancelId = `modalCancel_${this.order?._id}`
		this.wasPaid = this.order?.paymentStatus === ORDER_PAYMENT_STATUS.DONE

		this.canPay$ = of(
			this.order?.paymentStatus === ORDER_PAYMENT_STATUS.READY ||
				this.order?.paymentStatus === ORDER_PAYMENT_STATUS.FAILED
		).pipe(map(value => (this.hasVenueConfig ? value : false)))

		this.canCancel$ = this.nowEverySecond$.pipe(
			map(NOW => NOW <= this.reservationTime),
			map(value => (this.wasPaid ? false : value))
		)

		this.paymentBlocked$ = of(this.order?.paymentStatus === ORDER_PAYMENT_STATUS.NOT_DONE).pipe(
			map(value => (this.hasVenueConfig ? value : false))
		)

		this.hasVenueConfig = Boolean(this.order?.venueHasPaymentConfig)
	}

	get orderIntent() {
		if (!this.order) {
			return ''
		}
		const chooseIntent = {
			reservation: 'Reserva',
			checkin: 'CheckIn',
			waitlist: 'Fila de Espera',
		}
		return chooseIntent[this.order.intent]
	}

	private get reservationTime(): number {
		if (!this.order) {
			// reservationTime always in the future for return FALSE in canDay$
			return Infinity
		}
		const [day, _] = this.order?.reservationDay.split('T')
		return Date.parse(`${day}T${this.order.reservationTime}`)
	}

	private get isOrderEligible(): boolean {
		if (!this.order) {
			return false
		}
		return this.order.status === 'CHK_DONE' || this.order.status === 'VALIDATED'
	}

	openModalCancel(event: Event) {
		event.stopPropagation()
		this.modalService.open(this.modalCancelId)
	}

	cancelOrder() {
		if (!this.order) {
			return
		}
		this.store.dispatch(OrderActions.cancelOrder({ orderId: this.order._id }))
	}

	ngAfterViewInit(): void {
		this.subs.add(this.cancelSuccessAction)
		this.subs.add(this.cancelFailureAction)
	}

	private get cancelSuccessAction() {
		return this.actions$.pipe(ofType(OrderActions.cancelOrderSuccess)).subscribe(action => {
			// Because this subscription will fire for every order in the array, when
			// the action cancelOrderSuccess occur, it'll navigate to the last order._id which was subscribed
			// To prevent this, cancelOrderSuccess action receive the orderId which was initially fired
			if (action.orderId === this.order?._id) {
				this.router.navigate([`cancel-reservation/${this.order?._id}/success`])
				this.modalService.close(this.modalCancelId)
			}
		})
	}

	private get cancelFailureAction() {
		return this.actions$.pipe(ofType(OrderActions.cancelOrderFailure)).subscribe(action => {
			// Same as above
			if (action.orderId === this.order?._id) {
				this.router.navigate(['cancel-reservation/failure'])
				this.modalService.close(this.modalCancelId)
			}
		})
	}

	goToOrderDetails() {
		if (!this.order) {
			return
		}
		this.store.dispatch(OrderActions.setSelectedOrder({ orderId: this.order._id }))
		this.router.navigate([`minhas-reservas/${this.order._id}`])
	}

	goToPayments() {
		if (!this.order) {
			return
		}
		this.store.dispatch(OrderActions.setSelectedOrder({ orderId: this.order._id }))
		this.router.navigate([`pagamento-conta/${this.order._id}`])
	}
}
