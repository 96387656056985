import { Component, OnDestroy, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { ActivatedRoute, Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { AnimationOptions } from 'ngx-lottie'
import { BehaviorSubject, filter, Subscription, switchMap, take, tap } from 'rxjs'
import { PREFIX_TITLE } from 'src/app/shared/helpers/prefix-title'
import { LocalStorageService, LOCAL_KEYS } from 'src/app/shared/services/local-storage.service'
import { State } from 'src/app/state/app.state'
import { LOCAL_TOKEN } from 'src/app/state/client/client.effects'
import { getClient, getError, loadingClient, isLoggedIn } from 'src/app/state/client/client.reducers'
import * as ClientActions from '../../state/client/client.actions'

const Query = {
	token: 'token',
	esf_id: 'esf_id',
} as const

@Component({
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
	subs = new Subscription()
	error$ = this.store.select(getError)
	loading$ = this.store.select(loadingClient)
	private errorSub = new BehaviorSubject<string | null>(null)
	errorMessage$ = this.errorSub.asObservable()

	options: AnimationOptions = {
		path: '/assets/animations/overlayLoading.json',
	}
	constructor(
		private store: Store<State>,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private localStorageService: LocalStorageService,
		private title: Title
	) {}

	ngOnInit(): void {
		this.title.setTitle(`${PREFIX_TITLE} - Login`)
		// subscribe to error from back
		this.subs.add(this.setErrorFromBack)

		// subscribe to isLoggedIn to redirect the page
		this.subs.add(this.redirectAfterLoggedIn)

		// if the router returns a token and a identifier, try logging with SSO
		const esferaToken = this.activatedRoute.snapshot.queryParamMap.get(Query.token)
		const esferaIdentifier = this.activatedRoute.snapshot.queryParamMap.get(Query.esf_id)
		if (esferaToken && esferaIdentifier) {
			this.store.dispatch(ClientActions.loginClientSSO({ esferaToken, esferaIdentifier }))
			return
		}
		// if don't have token or identifier, try loading from localStorage
		const client = this.localStorageService.getTime(LOCAL_KEYS.USER)
		const accessToken = localStorage.getItem(LOCAL_TOKEN)
		if (client && accessToken) {
			this.store.dispatch(ClientActions.setClient({ client }))
			return
		} else {
			this.errorSub.next(`Algum problema aconteceu, por favor tente mais tarde.`)
		}
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe()
	}

	private get redirectAfterLoggedIn() {
		return this.store
			.select(isLoggedIn)
			.pipe(
				filter(value => value),
				take(1),
				switchMap(() => this.store.select(getClient))
			)
			.subscribe(clientState => {
				if (clientState.redirectUrl) {
					// Redirect from /login without changing browser history { skipLocationChange: true }
					this.router.navigate([`/${clientState.redirectUrl}`], { skipLocationChange: true })
				} else {
					this.router.navigate([`/restaurantes`])
				}
			})
	}

	private get setErrorFromBack() {
		return this.error$.subscribe(error => this.errorSub.next(error))
	}

	closeApp() {
		window.postMessage('esfera=close')
	}
}
