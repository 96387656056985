import { createAction, props } from '@ngrx/store'
import { Address } from './address'

// Address
export const getAddresses = createAction('[Venue Page] Get Addresses')
export const loadAddresses = createAction('[Venue Page] Load Addresses')
export const loadAddressesSuccess = createAction(
	'[Channels API] Load Addresses Success',
	props<{ addresses: Address[] }>()
)
export const loadAddressesFailure = createAction(
	'[Channels API] Load Addresses Fail',
	props<{ error: string }>()
)

export const setCurrentAddress = createAction(
	'[Venue Page] Set Current Address',
	props<{ address: string }>()
)
