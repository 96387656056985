<div class="modalContent">
	<div class="modalContent__header">
		<h2 class="modalContent__title">Alterar a Taxa de Serviço do garçom?</h2>
		<div class="modalContent__close" (click)="closeModal()">
			<i class="modalContent__close__icon fa fa-close"></i>
		</div>
	</div>
	<div class="modalContent__body">
		<div class="modalContent__line">
			<p class="modalContent__text">Valor sugerido: {{ suggestedTip }}%</p>
			<p id="rangeValue" class="modalContent__percent">
				{{ formTip.value.tip }}% ({{ billTip | currency : 'BRL' }})
			</p>
		</div>
		<div class="modalContent__adjustPercent">
			<ng-container [formGroup]="formTip">
				<input
					type="range"
					class="modalContent__range"
					type="range"
					[min]="suggestedTip"
					max="25"
					[defaultValue]="suggestedTip"
					formControlName="tip"
				/>
			</ng-container>
		</div>

		<p class="modalContent__text" *ngIf="hasTipChanged$ | async">
			<i class="modalContent__text__icon fa fa-smile"></i>
			Nossa equipe ficará muito feliz!
		</p>
		<!-- esse texto só aparecerá após o ajuste do percentual -->
	</div>
	<div class="modalContent__footer">
		<button class="modalContent__button button" (click)="changeTip()">Aplicar</button>
		<!-- este button inicia --DISABLE e só após o ajuste do percentual fica ativo -->
	</div>
</div>
