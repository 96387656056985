<div class="full-page-loading" *ngIf="loading$ | async">
	<div class="wrapper">
		<ng-lottie [options]="options"></ng-lottie>
	</div>
</div>

<div class="page" *ngIf="errorMessage$ | async as errorMessage">
	<div class="page__header">
		<section class="fail__header">
			<div class="fail__wrapper wrapper">
				<p class="fail__subtitle subtitle">Algo deu errado.</p>
			</div>
		</section>
	</div>
	<div class="page__body">
		<div class="fail__wrapper wrapper">
			<section class="fail__section section">
				<p class="fail__text">
					{{
						errorMessage.includes('desabilitado')
							? 'Essa conta está com o acesso temporariamente bloqueado, favor entrar em contato para realizar o desbloqueio'
							: errorMessage
					}}
				</p>
			</section>
		</div>
	</div>
	<div class="page__footer">
		<div class="fail__wrapper wrapper">
			<button class="fail__buttonGhost buttonGhost" (click)="closeApp()">Fechar</button>
		</div>
	</div>
</div>
