<div class="modalContent">
	<div class="modalContent__header">
		<h2 class="modalContent__title">{{ name }}</h2>
		<div class="modalContent__close" (click)="closeModal()">
			<i class="modalContent__close__icon fa fa-close"></i>
		</div>
	</div>
	<div class="modalContent__body">
		<p class="modalContent__text">
			{{ descriptionLong }}
		</p>
	</div>
</div>
