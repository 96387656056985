import { Component, HostBinding, Input } from '@angular/core'

@Component({
	selector: 'app-divisor',
	template: `<hr class="divisor" />`,
	styles: [
		`
			@import 'theme';
			.divisor {
				margin-top: var(--top);
				margin-bottom: var(--bottom);
				border: 1px solid $border;
				border-bottom: transparent;
			}
		`,
	],
})
export class DivisorComponent {
	@Input() top = '16px'
	@Input() bottom = '16px'

	@HostBinding('style')
	get addProps() {
		return {
			'--top': this.top,
			'--bottom': this.bottom,
		}
	}
}
