import { Injectable } from '@angular/core'
import { Observable, tap } from 'rxjs'
import { CreateCardDto, EncryptCardDataDto } from 'src/app/page/wallet/add-card/add-card.interface'
import { environment as env } from 'src/environments/environment'
import { HttpService } from '../../shared/services/http.service'
import { Client } from './client'
@Injectable({
	providedIn: 'root',
})
export class ClientService {
	constructor(private http: HttpService) {}

	loginClient(id: string): Observable<any> {
		return this.http.get<Client>(`api/v1/auth/login/homologation/${id}`)
	}

	getClient(id: string): Observable<any> {
		return this.http.get<Client>(`api/v1/clients/${id}`)
	}

	loginClientSSO(esferaToken: string, esferaIdentifier: string) {
		const body = {
			partnerId: env.partnerID,
			esferaToken,
			esferaIdentifier,
		}
		return this.http.post<Client>('api/v1/auth/esfera', body)
	}
	removeCard(clientId: string, lastFourDigits: string) {
		return this.http.delete(`api/v1/payments/remove-card/${clientId}/${lastFourDigits}`)
	}

	updateMainCard(clientId: string, lastFourDigits: string) {
		return this.http.patch(`api/v1/payments/main-card/${clientId}/${lastFourDigits}`)
	}

	updateClientById(id: string, client: Client) {
		return this.http.patch(`api/v1/clients/${id}`, client)
	}

	encrypt(body: EncryptCardDataDto): Observable<{ cardData: string }> {
		return this.http.post<{ cardData: string }>('api/v1/payments/encrypt', body)
	}

	createCardToken(createCard: CreateCardDto) {
		return this.http.post('api/v2/payments/create-card-token', createCard)
	}
}

export type LoginDto = {
	email: string
	phone: string
}
