import { Injectable } from '@angular/core'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { of } from 'rxjs'
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators'
import { State } from '../app.state'
import { getClient, getClientId } from '../client/client.reducers'
import { CheckInActions } from './check-in.actions'
import { getOrderId } from './check-in.reducers'
import { CheckInService } from './check-in.service'

@Injectable()
export class CheckInEffects {
	constructor(
		private checkinService: CheckInService,
		private actions$: Actions,
		private store: Store<State>
	) {}

	loadAvailabilities$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(CheckInActions.loadAvailabilities),
			concatLatestFrom(() => this.store.select(getClient)),
			mergeMap(([action, clientState]) => {
				return this.checkinService
					.getAvailabilities({
						venueId: action.venueId,
						clientId: clientState.client._id,
						categoryId: clientState.selectedCard.categoryId,
						intent: 'checkin',
					})
					.pipe(
						map(response => {
							if (!response.success) {
								return CheckInActions.checkinUnavailable({
									error: response.error?.message,
									availabilities: response.availabilities,
								})
							}
							return CheckInActions.loadAvailabilitiesSuccess({
								availabilities: response.availabilities,
								orderId: response.order._id,
							})
						}),
						catchError(error =>
							of(CheckInActions.loadAvailabilitiesFailure({ error: error.error?.message }))
						)
					)
			})
		)
	})

	confirmCheckin$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(CheckInActions.checkinConfirm),
			concatLatestFrom(() => [this.store.select(getOrderId), this.store.select(getClientId)]),
			mergeMap(([action, orderId, clientId]) =>
				this.checkinService
					.confirmCheckin(orderId as string, {
						reservationDay: action.day,
						reservationTime: action.time,
						partySize: action.partySize,
						clientId,
					})
					.pipe(
						map(() => CheckInActions.checkinConfirmSuccess()),
						catchError(error =>
							of(CheckInActions.checkinConfirmFailure({ error: error.error.message }))
						)
					)
			)
		)
	})
}
