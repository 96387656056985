import { Component, Input, OnInit, ViewChild } from '@angular/core'
import SwiperCore, { SwiperOptions, Autoplay, Pagination } from 'swiper'
import { SwiperComponent } from 'swiper/angular'
import { Slide } from './slide.interface'

SwiperCore.use([Autoplay, Pagination])

@Component({
	selector: 'app-slide',
	templateUrl: './slide.component.html',
	styleUrls: ['./slide.component.scss'],
})
export class SlideComponent implements OnInit {
	@ViewChild('swiper', { static: false }) swiper?: SwiperComponent

	@Input() slides: Slide[] | null = []

	public config: SwiperOptions = {
		slidesPerView: 1,
		spaceBetween: 1,
		navigation: false,
		pagination: {
			el: '.slide__control',
			type: 'bullets',
			bulletActiveClass: 'slide__control__item--checked',
			bulletClass: 'slide__control__item',
			bulletElement: 'div',
			clickable: true,
		},
		scrollbar: { draggable: true },
		autoplay: { delay: 5000 },
		speed: 1000,
		loop: true,
		breakpoints: {
			'576': {
				slidesPerView: 1,
				spaceBetween: 20,
				navigation: false,
				scrollbar: { draggable: true },
			},
		},
	}

	constructor() {}

	ngOnInit(): void {}

	onSwiper(swiper: any) {}

	onSlideChange() {}

	slideNext() {
		this.swiper?.swiperRef.slideNext(1000)
	}
	slidePrev() {
		this.swiper?.swiperRef.slidePrev(1000)
	}
	sliderGoTo(item: number) {
		this.swiper?.swiperRef.slideTo(item)
	}
}
