import { Component, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { Store } from '@ngrx/store'
import { PREFIX_TITLE } from 'src/app/shared/helpers/prefix-title'
import { State } from 'src/app/state/app.state'
import { getErrorCancel } from 'src/app/state/order/order.reducers'

@Component({
	selector: 'app-reservation-cancel-failure',
	templateUrl: './reservation-cancel-failure.component.html',
	styleUrls: ['./reservation-cancel-failure.component.scss'],
})
export class ReservationCancelFailureComponent implements OnInit {
	errorCancel$ = this.store.select(getErrorCancel)
	constructor(private store: Store<State>, private title: Title) {}

	ngOnInit(): void {
		this.title.setTitle(`${PREFIX_TITLE} - Cancelamento não pode ser realizado.`)
	}
}
