import { Injectable } from '@angular/core'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import { mergeMap, map, catchError, filter, switchMap } from 'rxjs/operators'
import { of } from 'rxjs'
import { Store } from '@ngrx/store'
import { State } from '../app.state'
import { OrderService } from './order.service'
import { getOrders, getPaymentOrder, getSelectedOrder } from './order.reducers'
import { getClient, getClientId } from '../client/client.reducers'
import { CreditCardHelper } from 'src/app/shared/helpers/credit-card.helper'
import { CreatePaymentDto } from './order'
import * as OrderActions from './order.actions'
import * as AvailabilityActions from '../reservation/reservation.actions'
import { environment as env } from 'src/environments/environment'

@Injectable()
export class OrderEffects {
	constructor(private actions$: Actions, private orderService: OrderService, private store: Store<State>) {}

	// Cached
	// getOrderHistory$ = createEffect(() => {
	// 	return this.actions$.pipe(
	// 		ofType(OrderActions.getOrderHistory),
	// 		concatLatestFrom(() => this.store.select(getOrders)),
	// 		// Only load venues if the array has at least one venue
	// 		filter(([_, orders]) => !Boolean(orders?.length)),
	// 		map(() => OrderActions.loadOrderHistory())
	// 	)
	// })

	// Call API (not cached)
	loadOrders$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(
				OrderActions.loadOrderHistory,
				OrderActions.createPaymentSuccess,
				OrderActions.cancelOrderSuccess,
				AvailabilityActions.confirmReservationSuccess
			),
			switchMap(() => this.store.select(getClientId)),
			mergeMap(clientId =>
				this.orderService.getHistory({ clientId: clientId }).pipe(
					map(orders => OrderActions.loadOrderHistorySuccess({ orders })),
					catchError(error => of(OrderActions.loadOrderHistoryFailure({ error })))
				)
			)
		)
	})

	createPayment$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(OrderActions.createPayment),
			concatLatestFrom(() => [
				this.store.select(getSelectedOrder),
				this.store.select(getPaymentOrder),
				this.store.select(getClient),
			]),
			mergeMap(([action, order, paymentOrder, clientState]) => {
				const paymentDto = {
					tableOrder: order?._id,
					orderId: order?._id,
					venueId: env.production ? order?.venueId : '56c776ff0896b3cd13c6012b',
					clientId: clientState.client._id,
					gatewayId: clientState.selectedCard.gatewayId,
					value: Math.round(paymentOrder.bill * 100), // Em centavos, mínimo 1000
					gratuityValue: Math.round(paymentOrder.gratuityValue * 100),
					dynamicCVV: action.createPaymentAction.dynamicCVV,
				}

				return this.orderService.createPayment(paymentDto as CreatePaymentDto).pipe(
					map(res => OrderActions.createPaymentSuccess({ res })),
					catchError(err => of(OrderActions.createPaymentFailure({ error: err.error.message })))
				)
			})
		)
	})

	cancelOrder$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(OrderActions.cancelOrder),
			mergeMap(action =>
				this.orderService.cancelOrder(action.orderId).pipe(
					map(() => OrderActions.cancelOrderSuccess({ orderId: action.orderId })),
					catchError(error =>
						of(
							OrderActions.cancelOrderFailure({
								error: error?.error?.message,
								orderId: action.orderId,
							})
						)
					)
				)
			)
		)
	})

	getOrderById$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(OrderActions.getOrderById),
			mergeMap(action =>
				this.orderService.getOrderById(action.orderId).pipe(
					map((order: any) => OrderActions.getOrderByIdSuccess({ order })),
					catchError(error => of(OrderActions.getOrderByIdFailure({ error })))
				)
			)
		)
	})

	getOrdersReady$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(OrderActions.getOrdersPaymentReady),
			concatLatestFrom(() => [this.store.select(getClientId)]),
			mergeMap(([_, clientId]) =>
				this.orderService.getOrdersPaymentReady(clientId).pipe(
					map((res: any) =>
						OrderActions.getOrdersPaymentReadySuccess({ hasPaymentReady: res.hasPaymentReady })
					),
					catchError(error => of(OrderActions.getOrdersPaymentReadyFailure()))
				)
			)
		)
	})
}
