import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router'
import { Store } from '@ngrx/store'
import { map, Observable, tap } from 'rxjs'
import { State } from '../app.state'
import { isLoggedIn } from './client.reducers'
import * as ClientActions from './client.actions'

@Injectable({
	providedIn: 'root',
})
export class AuthGuard implements CanActivate {
	constructor(private store: Store<State>, private router: Router) {}
	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.checkLoggedIn(state.url)
	}

	checkLoggedIn(url: string) {
		return this.store.select(isLoggedIn).pipe(
			map(value => {
				if (!value) {
					this.store.dispatch(ClientActions.setRedirectUrl({ url }))
					// Redirect to /noauth without changing browser history
					this.router.navigate(['/login'], { skipLocationChange: true })
					return false
				}
				return true
			})
		)
	}
}
