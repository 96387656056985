import { Component, Input, OnInit } from '@angular/core'
import { ModalService } from '../modal.service'

@Component({
	selector: 'app-modal-cvv',
	templateUrl: './modal-cvv.component.html',
	styleUrls: ['./modal-cvv.component.scss', './../modal-content.component.scss'],
})
export class ModalCvvComponent implements OnInit {
	@Input() id!: string
	constructor(private modalService: ModalService) {}

	ngOnInit(): void {}

	closeModal() {
		this.modalService.close(this.id)
	}
}
