import { createReducer, on, createFeatureSelector, createSelector } from '@ngrx/store'
import { Cuisine } from './cuisines'
import * as CuisinesActions from './cuisines.action'
import * as VenueActions from '../venue/venue.actions'

export interface CuisinesState {
	cuisines: Cuisine[] | null
	selectedCuisine: Cuisine
	error: string
}

export const DEFAULT_CUISINE_TEXT = 'Tipo de Culinária'

const initialState: CuisinesState = {
	cuisines: null,
	selectedCuisine: { _id: '', name: DEFAULT_CUISINE_TEXT },
	error: '',
}

const getCuisinesFeatureState = createFeatureSelector<CuisinesState>('cuisines')

export const getCuisines = createSelector(getCuisinesFeatureState, state => state.cuisines)
export const getSelectedCuisine = createSelector(getCuisinesFeatureState, state => state.selectedCuisine)
export const getError = createSelector(getCuisinesFeatureState, state => state.error)

export const cuisinesReducer = createReducer<CuisinesState>(
	initialState,
	on(CuisinesActions.setCurrentCuisine, (state, action): CuisinesState => {
		return {
			...state,
			selectedCuisine: action.cuisine,
		}
	}),
	on(CuisinesActions.loadCuisinesSuccess, (state, action): CuisinesState => {
		return {
			...state,
			cuisines: action.cuisines?.length ? action.cuisines : null,
			error: '',
		}
	}),
	on(CuisinesActions.loadCuisinesFailure, (state, action): CuisinesState => {
		return {
			...state,
			cuisines: null,
			error: action.error,
		}
	}),
	on(VenueActions.cleanFilter, (state): CuisinesState => {
		return {
			...state,
			selectedCuisine: { _id: '', name: DEFAULT_CUISINE_TEXT },
		}
	})
)
