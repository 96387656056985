<ch-box [type]="type">
	<ng-container *ngIf="templates.registerCard">
		Você ainda não tem um cartão cadastrado para pagar a conta nos restaurantes e ganhar pontos. Por
		favor, cadastre um cartão abaixo.
	</ng-container>

	<ng-container *ngIf="templates.validationCard">
		Para validação do seu cartão será feita uma cobrança de até R$5,00, em instantes será cancelada e o
		valor estornado.
	</ng-container>

	<ng-container *ngIf="templates.rememberPoints">
		Lembre-se de pagar a conta do restaurante pelo app Esfera para ganhar os pontos.
	</ng-container>

	<ng-container *ngIf="templates.reservationDisabled">
		Este restaurante está com a reserva desabilitada neste momento, por favor, tente mais tarde.
	</ng-container>

	<ng-container *ngIf="templates.adviceNoReservation"> Você não tem reservas no momento. </ng-container>

	<ng-container *ngIf="templates.noVenue"> Nenhum restaurante encontrado. </ng-container>

	<ng-container *ngIf="templates.enableCheckin"
		>Faça o check-in para habilitar o pagamento no app Esfera.</ng-container
	>

	<ng-container *ngIf="templates.unavailableCheckin">
		O Check-in não está habilitado no momento. Você pode consultar abaixo os horários disponíveis ou fazer
		uma reserva para este restaurante. Aproveite!
	</ng-container>
</ch-box>
