import { Component, OnDestroy, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { Store } from '@ngrx/store'
import { Subscription } from 'rxjs'
import { ModalService } from 'src/app/component/modal/modal.service'
import { PREFIX_TITLE } from 'src/app/shared/helpers/prefix-title'
import { State } from 'src/app/state/app.state'
import { getError, getOrders, loadingOrderHistory } from 'src/app/state/order/order.reducers'
import * as OrderActions from '../../state/order/order.actions'

@Component({
	templateUrl: './order-history.component.html',
	styleUrls: ['./order-history.component.scss'],
})
export class OrderPageComponent implements OnInit, OnDestroy {
	orders$ = this.store.select(getOrders)
	error$ = this.store.select(getError)
	loadingOrderHistory$ = this.store.select(loadingOrderHistory)
	errorModalId = 'modalErrorId'
	private subs = new Subscription()

	constructor(public modalService: ModalService, private store: Store<State>, private title: Title) {}

	ngOnInit(): void {
		this.title.setTitle(`${PREFIX_TITLE} - Minhas Reservas`)
		this.store.dispatch(OrderActions.loadOrderHistory())
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe()
	}
}
