import { Injectable } from '@angular/core'
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http'
import { Observable } from 'rxjs'
import { Router } from '@angular/router'
import { environment as env } from 'src/environments/environment'
import { LOCAL_CLIENT_ID } from 'src/app/state/client/client.effects'

@Injectable()
export class CustomHeadersInterceptor implements HttpInterceptor {
	constructor(private router: Router) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (request.url.startsWith('https://viacep')) {
			return next.handle(request)
		}
		const clientId = window.localStorage.getItem(LOCAL_CLIENT_ID)
		return next.handle(
			request.clone({ setHeaders: { partnerId: env.partnerID, ...(clientId ? { clientId } : {}) } })
		)
	}
}
