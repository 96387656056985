<div class="loading" *ngIf="isLoadingSelectedVenue$ | async">
	<app-spinner></app-spinner>
</div>
<div class="page" *ngIf="vm$ | async as vm">
	<div class="page__header">
		<app-slide [slides]="vm.slides"></app-slide>
	</div>

	<div class="page__body">
		<div class="venue__wrapper wrapper">
			<section class="venue__section section">
				<div class="venue__infos">
					<div class="venue__infos__top">
						<!-- NOME DO RESTAURANTE -->
						<h1 class="venue__title title">{{ vm.venue?.name }}</h1>
						<!-- INFORMAÇÕES SOBRE -->
						<div
							class="action"
							(click)="openModal(modalAboutId)"
							*ngIf="vm.venue?.descriptionLong"
						>
							<i class="action__icon fa fa-info"></i>
						</div>
					</div>

					<app-modal [id]="modalAboutId" top="35px">
						<app-modal-about
							[id]="modalAboutId"
							[name]="vm.venue?.name"
							[descriptionLong]="vm.venue?.descriptionLong"
						></app-modal-about>
					</app-modal>

					<!-- ENDEREÇO -->
					<p class="venue__text">{{ vm.venue?.address | formatAddress }}</p>

					<!-- TIPO DE CULINÁRIA -->
					<ul class="venue__tab" *ngFor="let cuisine of vm.venue?.cousines">
						<li class="venue__tab__item">{{ cuisine.name }}</li>
					</ul>

					<div class="venue__infos__actions">
						<a
							[href]="vm.venue?.hasMenu ? vm.liveMenuURL : ''"
							target="_blank"
							class="action"
							[ngClass]="vm.venue?.hasMenu ? '' : 'action--disabled'"
						>
							<i class="action__icon fa fa-menu"></i>
							<p class="action__text">Ver cardápio</p>
						</a>
						<div class="action" (click)="openModal(modalScheduleId)">
							<i class="action__icon fa fa-schedules"></i>
							<p class="action__text">Ver horários</p>
						</div>
					</div>

					<app-modal [id]="modalScheduleId" top="35px">
						<app-modal-schedules
							[operationHours]="vm.venue?.operationHours"
						></app-modal-schedules>
					</app-modal>

					<!-- BENEFÍCIOS -->
					<ng-container class="venue__benefit" *ngIf="selectedGeneric$ | async as generic">
						<app-benefit *ngIf="generic" [value]="generic.name" text="generic"></app-benefit>
						<br /><br />
					</ng-container>
					<ng-container class="venue__benefit" *ngIf="selectedPoints$ | async as benefit">
						<app-benefit [value]="benefit.value"></app-benefit>
					</ng-container>

					<!-- MAPA -->
					<div class="venue__map" *ngIf="vm.googleMaps">
						<img class="venue__map__image" [src]="vm.googleMaps" />
					</div>

					<!-- CONTATO -->
					<div class="venue__text">
						Mais informações: <br />
						<span class="venue__text--red" *ngFor="let phone of vm.phones">
							{{ phone | formatPhone }}
						</span>
					</div>
				</div>
			</section>
		</div>
	</div>

	<div style="height: 80px"></div>
	<div class="page__footer">
		<div class="venue__wrapper wrapper">
			<button
				class="venue__button buttonLight"
				*ngIf="vm.venue?.checkin?.enabled"
				[routerLink]="['/restaurantes', vm.venue?._id, 'checkin']"
				[ngStyle]="{ opacity: vm.venue?.checkin?.available ? '1' : '0.5' }"
			>
				Fazer check-in
			</button>
			<button
				class="venue__button button"
				[routerLink]="['/restaurantes', vm.venue?._id, 'reserva']"
				*ngIf="vm.venue?.reservation?.enabled"
			>
				Reservar uma mesa
			</button>

			<!-- quando o restaurante não tiver a opção da reserva,
				ao clicar, mostrar
				o app-box text="reservationDisabled" -->
			<!-- <div class="venue__box">
				<app-box text="reservationDisabled"></app-box>
			</div> -->
		</div>
	</div>
</div>
