import { Component, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { PREFIX_TITLE } from 'src/app/shared/helpers/prefix-title'

interface Instructions {
	number: number
	title: string
	text: string
}

@Component({
	selector: 'app-how-it-works',
	templateUrl: './how-it-works.component.html',
	styleUrls: ['./how-it-works.component.scss'],
})
export class HowItWorksComponent implements OnInit {
	instructions: Instructions[] = [
		{ number: 1, title: 'Escolha o restaurante', text: 'que deseja na sua localização' },
		{ number: 2, title: 'Selecione a data', text: 'e reserve uma mesa' },
		{
			number: 3,
			title: 'Pague a conta pelo App Esfera',
			text: 'em “Minhas Reservas” no Menu e aproveite!',
		},
		{ number: 4, title: 'Ganhe benefícios', text: 'acumule pontos, incentivos VIP e mais' },
	]

	constructor(private title: Title) {}

	pageTitle = 'Como Funciona'

	ngOnInit(): void {
		this.title.setTitle(`${PREFIX_TITLE} - ${this.pageTitle}`)
	}
}
