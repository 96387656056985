import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'

@Component({
	selector: 'app-modal-search',
	templateUrl: './modal-search.component.html',
	styleUrls: ['./modal-search.component.scss'],
})
export class ModalSearchComponent implements OnInit {
	searchForm!: FormGroup
	constructor(private fb: FormBuilder) {}
	@Output() submitted = new EventEmitter<string>()

	ngOnInit(): void {
		this.searchForm = this.fb.group({
			name: ['', Validators.required],
		})
	}

	submit() {
		if (this.searchForm.invalid) {
			return
		}
		const name = this.searchForm.value.name
		this.submitted.emit(name)
	}
}
