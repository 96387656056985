import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { State } from 'src/app/state/app.state'
import { environment as env } from '../../../environments/environment'

@Injectable({
	providedIn: 'root',
})
export class HttpService {
	token: string | undefined

	constructor(private http: HttpClient) {}

	get<Type>(url: string, params?: any): Observable<Type> {
		params = params ? '?' + new URLSearchParams(params).toString() : ''

		return this.http.get<any>(env.channelsAPI.baseUrl + '/' + url + params)
	}

	post<Type>(url: string, body: Object): Observable<Type> {
		return this.http.post<any>(env.channelsAPI.baseUrl + '/' + url, body)
	}

	patch<Type>(url: string, body: Object = {}): Observable<Type> {
		return this.http.patch<any>(env.channelsAPI.baseUrl + '/' + url, body)
	}

	delete<Type>(url: string): Observable<Type> {
		return this.http.delete<any>(env.channelsAPI.baseUrl + '/' + url)
	}
}
