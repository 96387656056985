import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { OrderVenueComponent } from './component/order/order-detail/order-detail.component'
import { HowItWorksComponent } from './page/how-it-works/how-it-works.component'
import { OrderPageComponent } from './page/orders/order-history.component'
import { PaymentBillComponent } from './page/payment/payment-bill/payment-bill.component'
import { PaymentCardComponent } from './page/payment/payment-card/payment-card.component'
import { PaymentConfirmComponent } from './page/payment/payment-confirm/payment-confirm.component'
import { ReservationConfirmComponent } from './page/reservation/reservation-confirm/reservation-confirm.component'
import { ReservationComponent } from './page/reservation/reservation.component'
import { VenuesPageComponent } from './page/venues/venues.component'
import { VenueDetailComponent } from './page/venues/venue-detail/venue-detail.component'
import { AddCardComponent } from './page/wallet/add-card/add-card.component'
import { WalletComponent } from './page/wallet/wallet.component'
import { ReservationFailureComponent } from './page/reservation/reservation-failure/reservation-failure.component'
import { AuthGuard } from './state/client/auth.guard'
import { LoginComponent } from './page/login/login.component'
import { ReservationCancelSuccessComponent } from './page/reservation/reservation-cancel-success/reservation-cancel-sucess.component'
import { ReservationCancelEmailSuccessComponent } from './page/reservation/reservation-cancel-email-success/reservation-cancel-email-success.component'
import { ReservationCancelFailureComponent } from './page/reservation/reservation-cancel-failure/reservation-cancel-failure.component'
import { ReservationCancelEmailFailureComponent } from './page/reservation/reservation-cancel-email-failure/reservation-cancel-email-failure.component'
import { CheckinAvailabilityPage } from './page/check-in/check-in-availability/check-in-availability.component'
import { CheckInConfirmPage } from './page/check-in/check-in-confirm/check-in-confirm.component'
import { TermsComponent } from './page/terms/terms.component'
import { RecommendationComponent } from './page/recommendation/recommendation.component'
import { RecommendationSuccessComponent } from './page/recommendation-success/recommendation-success.component'

const routes: Routes = [
	{ path: 'como-funciona', component: HowItWorksComponent },
	{ path: 'restaurantes', component: VenuesPageComponent, canActivate: [AuthGuard] },
	{
		path: 'restaurantes/:id',
		component: VenueDetailComponent,
		canActivate: [AuthGuard],
		pathMatch: 'full',
	},
	{
		path: 'restaurantes/:id/reserva',
		component: ReservationComponent,
		canActivate: [AuthGuard],
		pathMatch: 'full',
	},
	{
		path: 'restaurantes/:id/reserva-sucesso',
		component: ReservationConfirmComponent,
		canActivate: [AuthGuard],
	},
	{
		path: 'restaurantes/:id/reserva-erro',
		component: ReservationFailureComponent,
		canActivate: [AuthGuard],
	},
	{
		path: 'restaurantes/:id/checkin',
		component: CheckinAvailabilityPage,
		canActivate: [AuthGuard],
		pathMatch: 'full',
	},
	{
		path: 'restaurantes/:id/checkin-sucesso',
		component: CheckInConfirmPage,
		canActivate: [AuthGuard],
	},
	{ path: 'carteira', component: WalletComponent, canActivate: [AuthGuard] },
	{ path: 'carteira/adicionar-cartao', component: AddCardComponent, canActivate: [AuthGuard] },
	{ path: 'minhas-reservas', component: OrderPageComponent, canActivate: [AuthGuard] },
	{ path: 'minhas-reservas/:id', component: OrderVenueComponent, canActivate: [AuthGuard] },
	{ path: 'pagamento-conta/:id', component: PaymentBillComponent, canActivate: [AuthGuard] },
	{ path: 'pagamento-cartao/:id', component: PaymentCardComponent, canActivate: [AuthGuard] },
	{ path: 'pagamento-cartao/:id/sucesso', component: PaymentConfirmComponent, canActivate: [AuthGuard] },
	{ path: 'login', component: LoginComponent, pathMatch: 'full' },
	{ path: '', redirectTo: 'como-funciona', pathMatch: 'full' },

	{ path: 'cancel-reservation/:orderId/success', component: ReservationCancelSuccessComponent },
	{ path: 'cancel-reservation/failure', component: ReservationCancelFailureComponent },
	{ path: 'cancel-reservation-email/:orderId/sucesso', component: ReservationCancelEmailSuccessComponent },
	{ path: 'cancel-reservation-email/:orderId/failure', component: ReservationCancelEmailFailureComponent },
	{ path: 'termos-e-condicoes', component: TermsComponent },
	{ path: 'indicar-restaurante', component: RecommendationComponent },
	{ path: 'indicar-restaurante/sucesso', component: RecommendationSuccessComponent },
]

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
