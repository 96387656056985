<div class="page">
	<div class="page__header">
		<div class="addCard__wrapper wrapper">
			<h1 class="addCard__titleLight titleLight">Cadastrar cartão</h1>
		</div>
	</div>

	<div class="page__body">
		<div class="addCard__wrapper wrapper">
			<form class="addCard__form" [formGroup]="creditCardForm" (ngSubmit)="handleSubmit()">
				<!-- Numero do Cartão -->
				<div class="addCard__line">
					<!-- quando houver o preenchimento errado de um campo, acrescentar ao addCard__line, a class --ERROR -->
					<label for="number" class="addCard__label">
						<input
							id="number"
							class="addCard__input"
							mask="0000 0000 0000 0000||0000 000000 00000"
							formControlName="number"
							placeholder="0000  0000  0000  0000"
							inputmode="numeric"
						/>
						<span class="addCard__label__text">Número do cartão</span>
						<!-- <img
							src="./../../../../assets/images/card-elo.svg"
							alt="bandeira Elo Card"
							class="addCard__flag"
						/> -->
						<!-- aparece somente quando inserir os primeiros dígitos do cartão -->
					</label>
					<!-- <p class="addCard__line--error__text">Insira um número válido de 0-16 dígitos.</p> -->
					<!-- aparece somente quando o campo for preenchido de forma errada -->
				</div>

				<!-- Nome no Cartão -->
				<div class="addCard__line">
					<!-- quando houver o preenchimento errado de um campo, acrescentar ao addCard__line, a class --ERROR -->
					<label for="name" class="addCard__label">
						<input
							id="name"
							class="addCard__input"
							placeholder="Nome e sobrenome"
							pattern="[^()/><\][\\\x22,;|.]+"
							formControlName="name"
						/>
						<span class="addCard__label__text">Nome igual do cartão</span>
					</label>
					<!-- <p class="addCard__line--error__text">Insira o nome igual do cartão.</p> -->
					<!-- aparece somente quando o campo for preenchido de forma errada -->
				</div>
				<!-- CPF do Cartão -->
				<div class="addCard__line">
					<label for="cpf" class="addCard__label">
						<input
							id="cpf"
							class="addCard__input"
							placeholder="000.000.000-00"
							mask="000.000.000-00"
							formControlName="cpf"
						/>
						<span class="addCard__label__text">CPF</span>
					</label>
				</div>

				<!-- Validade -->
				<div class="twoColumns">
					<div class="addCard__line half">
						<!-- quando houver o preenchimento errado de um campo, acrescentar ao addCard__line, a class --ERROR -->
						<label for="validity" class="addCard__label">
							<input
								id="validity"
								class="addCard__input"
								mask="00/00"
								placeholder="MM/YY"
								[dropSpecialCharacters]="false"
								formControlName="validity"
								inputmode="numeric"
							/>
							<span class="addCard__label__text">Validade</span>
						</label>
						<!-- <p class="addCard__line--error__text">Data inválida.</p> -->
						<!-- aparece somente quando o campo for preenchido de forma errada -->
					</div>

					<!-- Código de Segurança -->
					<div class="addCard__line half">
						<!-- quando houver o preenchimento errado de um campo, acrescentar ao addCard__line, a class --ERROR -->
						<label for="cvv" class="addCard__label">
							<input
								id="cvv"
								type="text"
								class="addCard__input"
								mask="000||0000"
								formControlName="cvv"
								inputmode="numeric"
							/>
							<span class="addCard__label__text">CVV</span>
							<i
								class="addCard__label__icon fa fa-question"
								(click)="openModal(modalCvvId)"
							></i>
						</label>
						<!-- <p class="addCard__line--error__text">Insira CVV válido.</p> -->
						<!-- aparece somente quando o campo for preenchido de forma errada -->
					</div>
				</div>
				<h1 class="billingAddress__title">Endereço de cobrança</h1>
				<div formGroupName="billingAddress">
					<div class="addCard__line">
						<label for="zipCode" class="addCard__label">
							<input
								id="zipCode"
								class="addCard__input"
								mask="00000-000"
								formControlName="zipCode"
								type="text"
								inputmode="numeric"
							/>
							<span class="addCard__label__text">CEP</span>
						</label>
					</div>

					<div class="addCard__line">
						<label for="address" class="addCard__label">
							<input
								id="address"
								class="addCard__input"
								formControlName="address"
								type="text"
							/>
							<span class="addCard__label__text">Endereço</span>
						</label>
					</div>

					<div class="twoColumns">
						<div class="addCard__line half">
							<ch-select-box
								[items]="states"
								[selectedItem]="selectedState"
								(itemWasSelected)="setState($event)"
							></ch-select-box>
						</div>

						<div class="addCard__line half">
							<label for="city" class="addCard__label">
								<input id="city" class="addCard__input" formControlName="city" />
								<span class="addCard__label__text">Cidade</span>
							</label>
						</div>
					</div>
				</div>

				<app-modal [id]="modalCvvId" top="35px">
					<app-modal-cvv [id]="modalCvvId"></app-modal-cvv>
				</app-modal>

				<!-- SWITCH -->
				<div class="addCard__switch">
					<label class="switch">
						<p class="switch__txt">Cartão principal?</p>
						<input type="checkbox" class="switch__input" formControlName="mainCard" />
						<div class="switch__round"></div>
					</label>
				</div>

				<div class="addCard__box">
					<app-box type="success" text="validationCard"></app-box>
				</div>
			</form>
		</div>

		<div class="page__footer">
			<div class="addCard__wrapper wrapper">
				<ng-container *ngIf="{ loading: loadingNewCard$ | async } as obj">
					<button
						class="addCard__button button"
						(click)="handleSubmit()"
						[disabled]="creditCardForm.invalid || obj.loading"
						[ngClass]="{ 'button--disable': creditCardForm.invalid || obj.loading }"
					>
						Cadastrar
						<app-spinner class="loading" *ngIf="obj.loading"></app-spinner>
					</button>
				</ng-container>
			</div>
		</div>
		<app-modal [id]="modalAddCardSuccessId" top="35px" [preventOutsideClickClose]="true">
			<app-modal-added-card [id]="modalAddCardSuccessId"></app-modal-added-card>
		</app-modal>
	</div>
</div>

<app-modal [id]="modalAddCardFailureId" top="25%">
	<app-modal-failure
		[id]="modalAddCardFailureId"
		template="somethingWentWrong"
		*ngIf="error$ | async as error"
		[message]="error"
	></app-modal-failure>
</app-modal>
