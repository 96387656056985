<div class="page">
	<div class="page__header">
		<section class="pConfirm__header">
			<div class="pConfirm__wrapper wrapper">
				<p class="pConfirm__subtitle subtitle">
					Pagamento realizado<br />
					com sucesso!
				</p>
			</div>
		</section>
	</div>
	<div class="page__body">
		<div class="pConfirm__wrapper wrapper">
			<section class="pConfirm__ticket" *ngIf="vm$ | async as vm">
				<app-ticket label="Restaurante" [data]="vm.selectedOrder?.venue?.name"></app-ticket>
				<app-ticket
					label="Data"
					data="{{ vm.paymentOrder?.paidAt | date : 'dd/MM/YYYY' }} às {{
						vm.paymentOrder?.paidAt | date : 'HH:mm:ss'
					}}"
				></app-ticket>
				<app-ticket
					label="Cartão de Crédito"
					creditCard="masterCard"
					[data]="vm.clientState.selectedCard.lastFourDigits"
				></app-ticket>
				<app-ticket
					label="Valor pago"
					[data]="vm.paymentOrder?.totalValue?.toString() | currency : 'BRL'"
				></app-ticket>
				<app-ticket
					label="Pontos recebidos"
					data="{{ vm.paymentOrder?.points }} pontos"
					[tooltip]="true"
					[days]="1"
				></app-ticket>
				<app-ticket label="Código de Pagamento" [data]="vm.paymentOrder?.code"></app-ticket>
			</section>
		</div>
	</div>
	<div class="page__footer">
		<div class="pConfirm__wrapper wrapper">
			<button class="pConfirm__button button" [routerLink]="['/minhas-reservas']">Fechar</button>
		</div>
	</div>
</div>
