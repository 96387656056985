<div class="page">
	<div class="page__header">
		<section class="rCancel__header">
			<div class="rCancel__wrapper wrapper">
				<p class="rCancel__subtitle subtitle">
					O cancelamento da sua reserva<br />
					não pôde ser realizado.
				</p>
			</div>
		</section>
	</div>
	<div class="page__body">
		<div class="rCancel__wrapper wrapper">
			<section class="rCancel__section section">
				<p *ngIf="errorCancel$ | async as error">{{ error }}</p>
			</section>
		</div>
	</div>
	<div class="page__footer">
		<div class="rCancel__wrapper wrapper">
			<button class="rCancel__button button" [routerLink]="['/minhas-reservas']">
				Ver minhas reservas
			</button>
			<button class="rCancel__buttonGhost buttonGhost" [routerLink]="['/restaurantes']">
				Listar restaurantes
			</button>
		</div>
	</div>
</div>
