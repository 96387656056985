<div class="changeCard">
	<h3 class="changeCard__title">Selecionar cartão para pagamento</h3>

	<!-- <ng-content> -->
	<ul class="changeCard__list" *ngIf="cards$ | async as cards">
		<li class="changeCard__list__item" *ngFor="let card of cards">
			<div class="changeCard__left">
				<div class="changeCard__cardFlag">
					<img
						*ngIf="chooseBrand(card.brand) as imageBrand"
						src="{{ '/assets/images/' + imageBrand.src }}"
						[alt]="imageBrand.alt"
					/>
				</div>
				<div class="changeCard__cardNumber">
					<i class="changeCard__icon fa fa-bullets"></i>
					<p class="changeCard__cardNumber__theLast">{{ card.lastFourDigits }}</p>
				</div>
			</div>
			<div class="changeCard__right">
				<i class="changeCard__icon fa fa-star" *ngIf="card.mainCard"></i>

				<ng-container [formGroup]="form">
					<div class="changeCard__radio">
						<label class="radio">
							<input
								type="radio"
								class="radio__input"
								[value]="card"
								name="card"
								formControlName="card"
							/>
							<div class="radio__round">
								<i class="radio__round__icon fa fa-change-credit-card"></i>
							</div>
						</label>
					</div>
				</ng-container>
			</div>
		</li>
	</ul>

	<div class="changeCard__buttons">
		<button class="changeCard__button">
			<!-- ao clicar neste vai para a página adicionar cartão -->
			<span class="changeCard__button__text" [routerLink]="['/carteira', 'adicionar-cartao']"
				>Adicionar novo cartão</span
			>
		</button>
		<button class="changeCard__buttonGhost buttonGhost" (click)="closeModal()">Cancelar</button>
	</div>
</div>
