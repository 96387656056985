<div class="info">
	<ng-container *ngIf="templates.default">
		<p class="info__title">{{ title }}</p>
		<p class="info__text">{{ text }}</p>
	</ng-container>

	<ng-container *ngIf="templates.tip">
		<div (click)="clickIcon()">
			<div class="info__group">
				<p class="info__title">{{ title }}</p>
				<i class="info__icon fa fa-info"> </i>
			</div>
			<p class="info__subtitle">Incluir Taxa de Serviço adicional</p>
		</div>
		<p class="info__text">{{ text }}</p>
	</ng-container>

	<ng-container *ngIf="templates.points">
		<div class="info__group">
			<p class="info__title">{{ title }}</p>
			<i class="info__icon fa fa-info">
				<div class="tooltip">
					<p class="tooltip__text">O total de pontos não inclui a Taxa de Serviço</p>
				</div>
			</i>
		</div>
		<p class="info__text">{{ text }}</p>
	</ng-container>

	<ng-container *ngIf="templates.totalSum">
		<p class="info__title">{{ title }}</p>
		<p class="info__text">{{ text }}</p>
	</ng-container>
</div>
