import { Slide } from '../../../component/slide/slide.interface'
import { Venue } from '../../../state/venue/venue'
type Banners = Pick<Venue, 'partner'>['partner']['images']['banners']
type Address = Pick<Venue, 'address'>['address']

export function getSlides(banners: Banners | undefined): Slide[] {
	return (banners || []).map(item => ({
		title: '',
		text: '',
		alt: '',
		buttonText: '',
		imageDesktop: item?.coverUrl || './../../../../assets/images/img-restaurante.jpg',
		imageMobile: null,
		router: null,
		href: null,
	}))
}
