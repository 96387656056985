<div *ngIf="env.env !== 'production'" style="width: 400px; margin: 0 auto">
	<ng-container *ngIf="clientState$ | async as clientState">
		<form *ngIf="!clientState.client">
			<input
				[(ngModel)]="clientId"
				name="clientId"
				type="text"
				style="border: 1px solid black; border-radius: 8px; margin: 5px; width: 100%"
			/>
			<br />
			<button
				(click)="login()"
				style="background: #000; color: white; padding: 8px; border-radius: 8px"
			>
				Login
			</button>
		</form>
		<span *ngIf="clientState?.client">Login como {{ clientState.client?.name }}</span>
	</ng-container>
</div>

<app-modal [id]="modalLoginError" top="15%" animation="vertical">
	<app-modal-failure
		[id]="modalLoginError"
		message="Essa conta está com o acesso temporariamente bloqueado, favor entrar em contato para realizar o desbloqueio"
		template="somethingWentWrong"
	></app-modal-failure>
</app-modal>
<router-outlet></router-outlet>
