import { createReducer, on, createFeatureSelector, createSelector } from '@ngrx/store'
import { Address } from './address'
import * as AddressActions from './address.action'
import * as VenueActions from '../venue/venue.actions'

export interface AddressState {
	addresses: Address[]
	selectedAddress: Address
	error: string
}

export const DEFAULT_ADDRESS_TEXT = 'Todas as cidades'

const initialState: AddressState = {
	addresses: [],
	selectedAddress: DEFAULT_ADDRESS_TEXT,
	error: '',
}

const getAddressFeatureState = createFeatureSelector<AddressState>('address')

export const getAddresses = createSelector(getAddressFeatureState, state => state.addresses)
export const getSelectedAddress = createSelector(getAddressFeatureState, state => state.selectedAddress)
export const getError = createSelector(getAddressFeatureState, state => state.error)

export const addressReducer = createReducer<AddressState>(
	initialState,
	on(AddressActions.setCurrentAddress, (state, action): AddressState => {
		return {
			...state,
			selectedAddress: action.address,
		}
	}),
	on(AddressActions.loadAddressesSuccess, (state, action): AddressState => {
		return {
			...state,
			addresses: action.addresses,
			error: '',
		}
	}),
	on(AddressActions.loadAddressesFailure, (state, action): AddressState => {
		return {
			...state,
			addresses: [],
			error: action.error,
		}
	}),
	on(VenueActions.cleanFilter, (state): AddressState => {
		return {
			...state,
			selectedAddress: DEFAULT_ADDRESS_TEXT,
		}
	})
)
