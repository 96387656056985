import { Injectable } from '@angular/core'
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http'
import { Observable, tap } from 'rxjs'
import { LOCAL_TOKEN } from 'src/app/state/client/client.effects'
import { Router } from '@angular/router'

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	constructor(private router: Router) {}
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const token = localStorage.getItem(LOCAL_TOKEN)
		if (token) {
			request = request.clone({ setHeaders: { Authorization: `Bearer ${JSON.parse(token)}` } })
		}
		return next.handle(request).pipe(
			tap({
				error: (err: any) => {
					if (err instanceof HttpErrorResponse) {
						if (err.status === 401) {
							this.router.navigate(['/como-funciona'])
							localStorage.removeItem(LOCAL_TOKEN)
						}
					}
				},
			})
		)
	}
}
