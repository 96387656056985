import { Component, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { State } from 'src/app/state/app.state'
import { ModalService } from '../modal/modal.service'
import * as OrdersActions from '../../state/order/order.actions'
import { getHasPaymentReady } from 'src/app/state/order/order.reducers'

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
	modalHeaderId = 'modalHeaderId'

	hasPaymentReady$ = this.store.select(getHasPaymentReady)

	constructor(private modalService: ModalService, private store: Store<State>) {}

	ngOnInit(): void {
		this.store.dispatch(OrdersActions.getOrdersPaymentReady())
	}

	openModal() {
		this.modalService.open(this.modalHeaderId)
	}
}
