<div class="selectBox" [ngClass]="{ 'selectBox--opened ': isOpen }">
	<div *ngIf="isOpen" class="selectBox__background" (click)="toggleOpen()"></div>
	<div class="selectBox__current">
		<div class="selectBox__value" (click)="toggleOpen()">
			<i class="selectBox__icon fa {{ icon }}" *ngIf="icon"></i>
			<span class="selectBox__label">{{ selectedItem?.name }}</span>
		</div>
		<ul class="selectBox__options">
			<li
				class="selectBox__options__item"
				*ngFor="let item of filterItems"
				(click)="itemSelected(item)"
			>
				<!-- quando um item não estiver disponível, acrescentar a class --DISABLED -->
				{{ item.name }}
			</li>
		</ul>
	</div>
</div>
