import { Location } from '@angular/common'
import { Component, Input, OnInit } from '@angular/core'
import { ModalService } from '../modal.service'

type Templates =
	| 'cardNotAuthorized'
	| 'paymentNotApproved'
	| 'lostConnection'
	| 'somethingWentWrongGeneric'
	| 'somethingWentWrong'
@Component({
	selector: 'app-modal-failure',
	templateUrl: './modal-failure.component.html',
	styleUrls: ['./modal-failure.component.scss', './../modal-content.component.scss'],
})
export class ModalFailureComponent implements OnInit {
	@Input() id!: string
	@Input() cb: (() => void) | null = null
	@Input() message?: string | null = ''
	@Input() template: Templates = 'somethingWentWrongGeneric'

	constructor(public modalService: ModalService, private location: Location) {}

	templates: Record<Templates, boolean> = {
		cardNotAuthorized: false,
		paymentNotApproved: false,
		lostConnection: false,
		somethingWentWrongGeneric: false,
		somethingWentWrong: false,
	}

	ngOnInit(): void {
		if (!this.id) {
			console.error('Id obrigatório!')
		}
		this.activateTemplate(this.template)
	}

	activateTemplate(template: Templates) {
		this.templates[template] = true
	}

	closeModalFailure() {
		this.modalService.close(this.id)
		if (this.cb) {
			this.cb()
			this.location.back()
		}
	}
}
