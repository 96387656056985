<div class="modal-header">
	<div class="top">
		<button (click)="closeModal()">
			<p class="close-menu">Fechar menu</p>
			<!-- <i class="modalContent__close__icon fa fa-close" (click)="closeModal()"></i> -->
		</button>
	</div>
	<nav class="nav">
		<ul class="nav__list">
			<li class="nav__item" (click)="goTo('/restaurantes')">Restaurantes</li>
			<li class="nav__item payment-parent" (click)="goTo('/minhas-reservas')">
				Minhas Reservas e Pagamento
				<p class="payment-available" *ngIf="hasPaymentReady">Pagamento disponível</p>
			</li>
			<li class="nav__item" (click)="goTo('/carteira')">Carteira</li>
			<li class="nav__item" (click)="goTo('/como-funciona')">Como funciona</li>
			<li class="nav__item" (click)="goTo('/indicar-restaurante')">Indique um restaurante</li>
			<li class="nav__item" (click)="goTo('/termos-e-condicoes')">Termos e Condições</li>
		</ul>
	</nav>
</div>
