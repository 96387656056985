import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from '../../../environments/environment'

import { HttpService } from '../../shared/services/http.service'
import { ResponseReservationAvailability } from './reservation'

@Injectable({
	providedIn: 'root',
})
export class ReservationService {
	constructor(private http: HttpService) {}

	getAvailabilities(
		checkAvailabilityDto: CheckAvailabilityDto
	): Observable<ResponseReservationAvailability> {
		const body: CheckAvailabilityDto = {
			partnerId: environment.partnerID,
			...checkAvailabilityDto,
		}
		return this.http.post<ResponseReservationAvailability>('api/v1/orders/checkAvailability', body)
	}

	confirmReservation(orderId: string, confirmReservationDto: ConfirmReservationDto) {
		return this.http.post(`api/v1/orders/confirm/${orderId}`, confirmReservationDto)
	}
}
export interface CheckAvailabilityDto {
	partnerId?: string
	clientId: string
	venueId: string
	categoryId: string
}

export interface ConfirmReservationDto {
	partySize: number
	section: string // mongoId
	sectionLabel: string
	reservationDay: string // YYY-MM-DD
	reservationTime: string // HH:MM
	clientId: string
}
