import { Component, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { PREFIX_TITLE } from 'src/app/shared/helpers/prefix-title'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { RecommendationService } from './recommendation.service'
import { ModalService } from 'src/app/component/modal/modal.service'
import { Store } from '@ngrx/store'
import { getClient } from 'src/app/state/client/client.reducers'

@Component({
	selector: 'app-recommendation',
	templateUrl: './recommendation.component.html',
	styleUrls: ['./recommendation.component.scss'],
})
export class RecommendationComponent implements OnInit {
	responseForm: FormGroup = this.fb.group({
		checkboxes: ['Cliente', Validators.required],
		venueName: ['', Validators.required],
		city: ['', Validators.required],
		state: ['', Validators.required],
		neighborhood: [''],
	})
	pageTitle = 'Indique um restaurante'

	ufs: string[] = [
		'AC',
		'AL',
		'AP',
		'AM',
		'BA',
		'CE',
		'DF',
		'ES',
		'GO',
		'MA',
		'MT',
		'MS',
		'MG',
		'PA',
		'PB',
		'PR',
		'PE',
		'PI',
		'RJ',
		'RN',
		'RS',
		'RO',
		'RR',
		'SC',
		'SP',
		'SE',
		'TO',
	]
	modalErrorId = 'modal-error' as const

	clientInfo: any

	constructor(
		public modalService: ModalService,
		private title: Title,
		private fb: FormBuilder,
		private router: Router,
		private recommendationService: RecommendationService,
		private store: Store<any>
	) {}

	ngOnInit(): void {
		this.title.setTitle(`${PREFIX_TITLE} - ${this.pageTitle}`)
		this.store.select(getClient).subscribe(info => (this.clientInfo = info.client))
	}

	onSubmit(): void {
		const formValuesString = `
<pre>
Origem: ESFERA
Indicação do: ${this.responseForm.value.checkboxes}
Nome: ${this.clientInfo.name}
Email:${this.clientInfo.email}
Nome do Restaurante: ${this.responseForm.value.venueName}
Local:${this.responseForm.value.city} - ${this.responseForm.value.state}, bairro: ${this.responseForm.value.neighborhood}</pre>`
		this.recommendationService.sendEmail(formValuesString).subscribe({
			next: () => this.router.navigate(['/indicar-restaurante/sucesso']),
			error: () => this.modalService.open(this.modalErrorId),
		})
	}
}
