<div class="modalContent">
	<div class="modalContent__header">
		<h2 class="modalContent__title">
			Tem certeza que deseja<br />
			{{ templates[template] }}
		</h2>
		<div class="modalContent__close" (click)="closeModal()">
			<i class="modalContent__close__icon fa fa-close"></i>
		</div>
	</div>
	<div class="modalContent__body">
		<div class="modalContent__buttons">
			<button
				class="modalContent__buttonGhost buttonGhost"
				(click)="cancel()"
				[disabled]="loading"
				[class.button--disable]="loading"
				style="position: relative"
			>
				Sim
				<app-spinner class="loading" size="small" *ngIf="loading"></app-spinner>
			</button>
			<button
				class="modalContent__button button"
				(click)="closeModal()"
				[class.button--disable]="loading"
			>
				Não
			</button>
		</div>
	</div>
</div>
