import { Location } from '@angular/common'
import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { ModalService } from '../modal.service'

@Component({
	selector: 'app-modal-register-card',
	templateUrl: './modal-register-card.component.html',
	styleUrls: ['./modal-register-card.component.scss', './../modal-content.component.scss'],
})
export class ModalRegisterCardComponent implements OnInit {
	@Input() id!: string
	constructor(private modalService: ModalService, private location: Location) {}

	ngOnInit(): void {}

	close() {
		this.modalService.close(this.id)
		this.location.back()
	}
}
