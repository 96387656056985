<div class="page">
	<div class="page__header">
		<section class="rCancel__header">
			<div class="rCancel__wrapper wrapper">
				<p class="rCancel__subtitle subtitle">
					Sua reserva foi cancelada <br />
					com sucesso!
				</p>
			</div>
		</section>
	</div>
	<div class="page__body" *ngIf="orderById$ | async as order">
		<div class="rCancel__wrapper wrapper">
			<section class="rCancel__section section">
				<section class="rCancel__ticket">
					<app-ticket label="Nome" [data]="order.client?.name"></app-ticket>
					<app-ticket label="Restaurante" [data]="order.venue?.name"></app-ticket>
					<app-ticket
						label="Data da reserva"
						[data]="order.reservationDay | date : 'dd/MM/YYYY'"
					></app-ticket>
					<app-ticket
						label="Data da reserva cancelada"
						[data]="order?.canceledAt | date : 'dd/MM/YYYY'"
					></app-ticket>
				</section>
			</section>
		</div>
	</div>
	<div class="page__footer">
		<div class="rCancel__wrapper wrapper">
			<button class="rCancel__button button" [routerLink]="['/minhas-reservas']">
				Ver minhas reservas
			</button>
			<button class="rCancel__buttonGhost buttonGhost" [routerLink]="['/restaurantes']">
				Listar restaurantes
			</button>
		</div>
	</div>
</div>
