<div class="search">
	<form class="search__content" (submit)="submit()" [formGroup]="searchForm">
		<div class="search__form">
			<div class="search__field">
				<input
					class="search__field__input input"
					type="text"
					placeholder="Buscar restaurantes"
					formControlName="name"
				/>
				<!--
                <p class="search__field__notFound">
                    Não encontramos resultado para sua busca.<br />
                    Tente uma nova busca.
                </p>
                -->
			</div>
			<button class="search__action action">
				<i class="search__action__icon action__icon fa fa-search"></i>
			</button>
		</div>
	</form>
</div>
