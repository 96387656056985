import { createReducer, on, createFeatureSelector, createSelector } from '@ngrx/store'
import { Availability, ReservationTime, Schedules, Section } from './reservation'
import * as AvailabilityActions from './reservation.actions'

export interface AvailabilityState {
	availabilities: Availability[] | null
	loadingAvailabilities: boolean
	selectedDate: string | null
	sections: Section[] | undefined | null
	selectedSectionLabel: string | null
	selectedSectionId: string | null
	schedules: Schedules[] | undefined | null
	selectedPartySize: string | null
	reservationTimes: ReservationTime[] | undefined | null
	selectedReservationTime: string | null
	errorAvailability: string | null
	loadingConfirmReservation: boolean
	errorConfirmation: string | null
	orderId: string | null
}

export const CHOOSE_DATE = 'Escolha a data'
export const CHOOSE_SECTION = 'Selecione o ambiente'
export const CHOOSE_PARTYSIZE = 'Selecione a qtd de pessoas'

const resetState = {
	selectedDate: CHOOSE_DATE,
	loadingAvailabilities: false,
	sections: null,
	selectedSectionLabel: CHOOSE_SECTION,
	selectedSectionId: null,
	schedules: null,
	selectedPartySize: CHOOSE_PARTYSIZE,
	reservationTimes: null,
	selectedReservationTime: null,
	errorAvailability: null,
	loadingConfirmReservation: false,
	errorConfirmation: null,
}

const initialState: AvailabilityState = {
	availabilities: null,
	orderId: null,
	...resetState,
}

const getAvailabilityFeatureState = createFeatureSelector<AvailabilityState>('availability')

export const isLoadingAvailabililities = createSelector(
	getAvailabilityFeatureState,
	state => state.loadingAvailabilities
)

export const isLoadingConfirmReservation = createSelector(
	getAvailabilityFeatureState,
	state => state.loadingConfirmReservation
)
export const getAvailabilities = createSelector(getAvailabilityFeatureState, state => state.availabilities)
export const getSelectedDate = createSelector(getAvailabilityFeatureState, state => state.selectedDate)
export const getSections = createSelector(getAvailabilityFeatureState, state => state.sections)
export const getSelectedSection = createSelector(
	getAvailabilityFeatureState,
	state => state.selectedSectionLabel
)
export const getSchedules = createSelector(getAvailabilityFeatureState, state => state.schedules)
export const getSelectedPartySize = createSelector(
	getAvailabilityFeatureState,
	state => state.selectedPartySize
)
export const getReservationTime = createSelector(getAvailabilityFeatureState, state => state.reservationTimes)
export const getSelectedReservationTime = createSelector(
	getAvailabilityFeatureState,
	state => state.selectedReservationTime
)
export const getErrorAvailability = createSelector(
	getAvailabilityFeatureState,
	state => state.errorAvailability
)
export const getErrorConfirmation = createSelector(
	getAvailabilityFeatureState,
	state => state.errorConfirmation
)
export const getOrderId = createSelector(getAvailabilityFeatureState, state => state.orderId)
export const getConfirmReservation = createSelector(getAvailabilityFeatureState, state => ({
	partySize: Number(state.selectedPartySize),
	sectionLabel: state.selectedSectionLabel,
	reservationDay: state.selectedDate,
	reservationTime: state.selectedReservationTime,
	section: state.selectedSectionId,
}))

export const reservationReducer = createReducer<AvailabilityState>(
	initialState,
	on(AvailabilityActions.setOrderId, (state, action): AvailabilityState => {
		return {
			...state,
			orderId: action.orderId,
		}
	}),
	on(AvailabilityActions.setSelectedDate, (state, action): AvailabilityState => {
		const sections = state.availabilities?.find(av => av.reservationDay === action.selectedDate)?.sections
		return {
			...state,
			...resetState,
			selectedDate: action.selectedDate,
			sections: sections?.filter(section => section.available),
		}
	}),
	on(AvailabilityActions.setSection, (state, action): AvailabilityState => {
		const section = state.sections?.find(sc => sc.label === action.selectedSectionLabel) as Section
		return {
			...state,
			selectedPartySize: CHOOSE_PARTYSIZE,
			selectedReservationTime: null,
			reservationTimes: null,
			selectedSectionLabel: action.selectedSectionLabel,
			schedules: section?.schedules.filter(schedule => schedule.available),
			selectedSectionId: section?.id,
		}
	}),
	on(AvailabilityActions.setSchedule, (state, action): AvailabilityState => {
		const reservationTimes = state.schedules?.find(
			schedule => `${schedule.partySize}` === action.selectedPartySize
		)?.reservationTimes
		return {
			...state,
			selectedPartySize: action.selectedPartySize,
			selectedReservationTime: null,
			reservationTimes: reservationTimes?.filter(time => time.available),
		}
	}),
	on(AvailabilityActions.setReservationTime, (state, action): AvailabilityState => {
		return {
			...state,
			selectedReservationTime: action.selectedReservationTime,
		}
	}),
	on(AvailabilityActions.loadAvailabilitiesSuccess, (state, action): AvailabilityState => {
		return {
			...state,
			errorAvailability: null,
			loadingAvailabilities: false,
			availabilities: action.availabilities.filter(availability => availability.available),
		}
	}),
	on(AvailabilityActions.loadAvailabilitiesFailure, (state, action): AvailabilityState => {
		return {
			...state,
			errorAvailability: action.error,
			loadingAvailabilities: false,
			availabilities: [],
		}
	}),
	on(AvailabilityActions.loadAvailabilities, (state, action): AvailabilityState => {
		return {
			...state,
			loadingAvailabilities: true,
			errorAvailability: null,
		}
	}),
	on(AvailabilityActions.confirmReservation, (state): AvailabilityState => {
		return {
			...state,
			loadingConfirmReservation: true,
			errorConfirmation: null,
		}
	}),
	on(AvailabilityActions.confirmReservationSuccess, (state): AvailabilityState => {
		return {
			...state,
			loadingConfirmReservation: false,
			errorConfirmation: null,
		}
	}),
	on(AvailabilityActions.confirmReservationFailure, (state, action): AvailabilityState => {
		return {
			...state,
			errorConfirmation: action.error,
			loadingConfirmReservation: false,
		}
	}),
	on(AvailabilityActions.resetReservationState, (state): AvailabilityState => {
		return {
			...initialState,
		}
	})
)
