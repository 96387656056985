import { ChangeDetectionStrategy } from '@angular/core'
import { Component, Input, OnInit } from '@angular/core'
import { Venue } from '../../state/venue/venue'

@Component({
	selector: 'app-venue-card',
	templateUrl: './venue-card.component.html',
	styleUrls: ['./venue-card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VenueCardComponent implements OnInit {
	@Input() venue!: Venue
	constructor() {}

	ngOnInit(): void {}

	get venuePicture() {
		return this.venue.partner?.images?.thumb[0]?.coverUrl
	}

	//Lógica duplicada em venue.reducer.ts
	get benefit() {
		return this.venue.benefits.find(benefit => benefit.type === 'points')
	}

	//Lógica duplicada em venue.reducer.ts
	get generic() {
		return this.venue.benefits.find(benefit => benefit.type === 'generic')
	}
}
