import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { BoxComponent } from './box/box.component'
import { SelectBoxComponent } from './select-box/select-box.component'

@NgModule({
	declarations: [BoxComponent, SelectBoxComponent],
	imports: [CommonModule],
	exports: [BoxComponent, SelectBoxComponent],
})
export class TagmeChannelsComponents {}
