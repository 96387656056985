import { Component, Input, OnInit } from '@angular/core'

@Component({
	selector: 'ch-box',
	templateUrl: './box.component.html',
	styleUrls: ['./box.component.scss'],
})
export class BoxComponent implements OnInit {
	@Input() type: 'reminder' | 'success' | 'advice' = 'reminder'

	classes = {
		reminder: 'box--reminder',
		success: 'box--success',
		advice: 'box--advice',
	}

	constructor() {}

	ngOnInit(): void {}

	get chooseType() {
		return this.classes[this.type]
	}
}
