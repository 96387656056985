import { Component, Input } from '@angular/core'
import { ModalService } from '../modal.service'

@Component({
	selector: 'app-modal-terms',
	templateUrl: './modal-terms.component.html',
	styleUrls: ['./modal-terms.component.scss', './../modal-content.component.scss'],
})
export class ModalTermsComponent {
	@Input() id: string = ''
	constructor(public modalService: ModalService) {}

	closeModal() {
		this.modalService.close(this.id)
	}
}
