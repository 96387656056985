import { createAction, props } from '@ngrx/store'
import { Availability } from './reservation'

export const loadAvailabilities = createAction(
	'[Reservation Page] Load Availabilities',
	props<{ venueId: string }>()
)

export const setSelectedDate = createAction(
	'[Reservation Page] Set Selected Date',
	props<{ selectedDate: string }>()
)

export const setSection = createAction(
	'[Reservation Page] Set Selected Section',
	props<{ selectedSectionLabel: string }>()
)

export const setSchedule = createAction(
	'[Reservation Page] Set Schedule ',
	props<{ selectedPartySize: string }>()
)

export const setReservationTime = createAction(
	'[Reservation Page] Set ReservationTime ',
	props<{ selectedReservationTime: string }>()
)

export const loadAvailabilitiesSuccess = createAction(
	'[Channels API] Load Availabilities Success',
	props<{ availabilities: Availability[] }>()
)
export const loadAvailabilitiesFailure = createAction(
	'[Channels API] Load Availabilities Fail',
	props<{ error: string }>()
)

export const setOrderId = createAction(
	'[Channels API] Set OrderId after Loading Availabilities',
	props<{ orderId: string }>()
)

export const confirmReservation = createAction('[Channels API] POST Confirmm Reservation')

export const confirmReservationSuccess = createAction('[Channels API] Confirm Reservation Success')

export const confirmReservationFailure = createAction(
	'[Channels API] Confirm Reservation Fail',
	props<{ error: string }>()
)

export const resetReservationState = createAction('[Channels API] Reset Reservation State')
