import { createAction, props } from '@ngrx/store'
import { CreatePaymentAction, OrderFromHistory, PaymentOrder } from './order'

// Get and Set Selected Order
// export const getOrderHistory = createAction('[Order History Page] Get Order History')
export const setSelectedOrder = createAction('[Order History Page] Set Orders', props<{ orderId: string }>())

// Cancel Order
export const cancelOrder = createAction('[Order History Page] Cancel Order', props<{ orderId: string }>())
export const cancelOrderSuccess = createAction(
	'[Order History Page] Cancel Order Success',
	props<{ orderId: string }>()
)
export const cancelOrderFailure = createAction(
	'[Order History Page] Cancel Order Failure',
	props<{ error: string; orderId: string }>()
)

// Get Order by Id
export const getOrderById = createAction('[Order Action] Get Order Id', props<{ orderId: string }>())
export const getOrderByIdSuccess = createAction(
	'[Order Action] Get Order Id Success',
	props<{ order: OrderFromHistory }>()
)
export const getOrderByIdFailure = createAction(
	'[Order Action] Get Order Id Failure',
	props<{ error: string }>()
)

// Load order history
export const loadOrderHistory = createAction('[Order History Page] Load Orders')
export const loadOrderHistorySuccess = createAction(
	'[Order History Page] Load Orders Success',
	props<{ orders: OrderFromHistory[] }>()
)
export const loadOrderHistoryFailure = createAction(
	'[Order History Page] Load Orders Failure',
	props<{ error: string }>()
)

// Payment on Order
export const setPaymentOrder = createAction(
	'[Payment Bill Page] Set Payment Order',
	props<{ paymentOrder: PaymentOrder }>()
)
export const createPayment = createAction(
	'[Payment Card Page] Create Payment',
	props<{ createPaymentAction: CreatePaymentAction }>()
)
export const createPaymentSuccess = createAction(
	'[Payment Card Page] Create Payment Success',
	props<{ res: any }>()
)
export const createPaymentFailure = createAction(
	'[Payment Card Page] Create Payment Failure',
	props<{ error: string }>()
)

export const getOrdersPaymentReady = createAction('[Header Component] Get Orders Payment Ready')
export const getOrdersPaymentReadySuccess = createAction(
	'[Header Component] Get Orders Payment Ready Success',
	props<{ hasPaymentReady: boolean }>()
)
export const getOrdersPaymentReadyFailure = createAction(
	'[Header Component] Get Orders Payment Ready Failure'
)
