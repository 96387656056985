import { Location } from '@angular/common'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { Store } from '@ngrx/store'
import { filter, Subscription } from 'rxjs'
import { PREFIX_TITLE } from 'src/app/shared/helpers/prefix-title'
import { State } from 'src/app/state/app.state'
import { getErrorConfirmation } from 'src/app/state/reservation/reservation.reducers'
import { getVenue } from 'src/app/state/venue/venue.reducers'

@Component({
	templateUrl: './reservation-failure.component.html',
	styleUrls: ['./reservation-failure.component.scss'],
})
export class ReservationFailureComponent implements OnInit, OnDestroy {
	error$ = this.store.select(getErrorConfirmation)

	private subs = new Subscription()
	constructor(private location: Location, private store: Store<State>, private title: Title) {}

	ngOnInit(): void {
		this.subs.add(
			this.store
				.select(getVenue)
				.pipe(filter(value => Boolean(value)))
				.subscribe(venue =>
					this.title.setTitle(`${PREFIX_TITLE} - ${venue?.name} - Erro na Reserva!`)
				)
		)
	}
	ngOnDestroy(): void {
		this.subs.unsubscribe()
	}

	goBack() {
		this.location.back()
	}
}
