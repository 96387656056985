import { Injectable } from '@angular/core'
import { CryptoService } from './crypto.service'

export enum LOCAL_KEYS {
	USER = 'USER_ESFERA',
}

@Injectable({
	providedIn: 'root',
})
export class LocalStorageService {
	constructor(private cryptoJS: CryptoService) {}

	get(key: string) {
		let data = JSON.parse(localStorage.getItem(key) || '{}')
		data = this.isHttps() ? this.cryptoJS.decode(data) : data
		return Object.keys(data).length ? data : null
	}

	getTime(key: string) {
		const storedTime = localStorage.getItem(`${key}_TIME`)
		const currentTime = new Date().getTime()

		if (Number(storedTime) < currentTime) {
			// Item has expired
			this.remove(key)
			this.remove(`${key}_TIME`)
			return null
		}
		// Item has not expired, retrieve the data
		return this.get(key)
	}

	set(key: string, data: any) {
		data = JSON.stringify(this.isHttps() ? this.cryptoJS.encode(data) : data)
		localStorage.setItem(key, data)
	}

	// 86 400 000 equal 1 day in miliseconds
	setTime(key: string, data: any, expirationTime = 86_400_000) {
		// Get current time in miliseconds
		let currentTime = new Date().getTime()

		// Set the item in localStorage
		this.set(key, data)

		// Set the expiration time in localStorage
		localStorage.setItem(`${key}_TIME`, String(currentTime + expirationTime))
	}

	remove(key: string) {
		localStorage.removeItem(key)
	}

	private isHttps() {
		return location.protocol === 'https:'
	}
}
