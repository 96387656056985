import { createAction, props } from '@ngrx/store'
import { Cuisine } from './cuisines'

export const getCuisines = createAction('[Venue Page] Get Cuisines')
export const loadCuisines = createAction('[Venue Page] Load Cuisines')
export const loadCuisinesSuccess = createAction(
	'[Channels API] Load Cuisines Success',
	props<{ cuisines: Cuisine[] }>()
)
export const loadCuisinesFailure = createAction(
	'[Channels API] Load Cuisines Fail',
	props<{ error: string }>()
)

export const setCurrentCuisine = createAction(
	'[Venue Page] Set current cuisine',
	props<{ cuisine: Cuisine }>()
)
