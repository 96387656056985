import { DOCUMENT } from '@angular/common'
import {
	Component,
	ElementRef,
	Input,
	OnInit,
	OnDestroy,
	ViewChild,
	Inject,
	HostBinding,
} from '@angular/core'
import { ModalService } from './modal.service'

@Component({
	selector: 'app-modal',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit, OnDestroy {
	@Input() id!: string
	@Input() preventOutsideClickClose?: boolean = false
	@Input() top?: string = 'inherit' // Required to use top or bottom input for Modal works correctly
	@Input() bottom?: string = 'inherit'
	@Input() animation: 'horizontal' | 'vertical' | 'vertical-i' | 'horizontal-i' = 'vertical'
	@ViewChild('modal') modal!: ElementRef

	constructor(
		private modalService: ModalService,
		private el: ElementRef,
		@Inject(DOCUMENT) private document: Document
	) {}

	ngOnInit(): void {
		if (!this.id) {
			console.error('modal must have an id')
			return
		}

		// Add this element on document
		this.document.body.appendChild(this.el.nativeElement)

		this.el.nativeElement.addEventListener('click', (e: any) => {
			if (e.target.className === 'modal__after' && !this.preventOutsideClickClose) {
				this.close()
			}
		})

		// add self (this modal instance) to the modal service so it's accessible from controllers
		this.modalService.add(this)
	}

	@HostBinding('style')
	get addProps() {
		const configPosition = {
			'--modal-top': this.top,
			'--modal-bottom': this.bottom,
		}

		const configAnimation = {
			vertical: {
				'--time': '0.5s',
				'--animation-opened': 'toBottom',
				'--animation-closed': 'toTop',
			},
			horizontal: {
				'--time': '0.5s',
				'--animation-opened': 'toLeft',
				'--animation-closed': 'toRight',
			},
			'vertical-i': {
				'--time': '0.5s',
				'--animation-opened': 'toTop-i',
				'--animation-closed': 'toBottom-i',
			},
			'horizontal-i': {
				'--time': '0.5s',
				'--animation-opened': 'toRight-i',
				'--animation-closed': 'toLeft-i',
			},
		}
		return { ...configPosition, ...configAnimation[this.animation] }
	}

	// remove self from modal service when component is destroyed
	ngOnDestroy(): void {
		this.modalService.remove(this.id)
		this.el.nativeElement.remove()
	}

	open(): void {
		this.modal.nativeElement.classList.add('opened')
	}

	close(): void {
		this.modal.nativeElement.classList.remove('opened')
	}
}
