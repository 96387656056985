<li class="order__item" *ngIf="order" (click)="goToOrderDetails()">
	<div class="order__item__left">
		<p class="order__item__title" (click)="goToOrderDetails()">{{ order.venue.name }}</p>
		<!-- ao clicar no nome do restaurante leva para ORDER VENUE, que é a página específica do restaurante em minhas reservas -->
		<p class="order__item__text">{{ order.venue.address | formatAddress }}</p>

		<div class="order__item__bottom">
			<div class="order__tab">
				<p class="order__tab__item">{{ orderIntent }} às {{ order.reservationTime }}</p>
			</div>
			<button class="order__item__link" (click)="openModalCancel($event)" *ngIf="canCancel$ | async">
				Cancelar Reserva
			</button>
			<!-- <button class="order__item__link" (click)="openModalCancel($event)">Cancelar reserva</button> -->
		</div>
		<button class="order__item__button button" *ngIf="canPay$ | async" (click)="goToPayments()">
			Pagar conta
		</button>

		<!-- <button class="order__item__button button" (click)="goToPayments()">Pagar conta</button> -->
		<p class="order__payment order__payment--made" *ngIf="wasPaid">Pagamento realizado</p>

		<p class="order__payment order__payment--unidentified" *ngIf="paymentBlocked$ | async">
			Pagamento não-identificado
		</p>

		<p class="order__payment order__payment--unidentified" *ngIf="!hasVenueConfig">
			Impossibilidade de pagamento.
		</p>
	</div>

	<div class="order__item__right">
		<div class="order__date">
			<p class="order__date__day">{{ order.reservationDay | date : 'dd' }}</p>
			<p class="order__date__month">
				{{ order.reservationDay | date : 'MMM' | replace : '[.]' : ' ' }}
			</p>
		</div>
		<div class="order__points" *ngIf="wasPaid">
			<p class="order__points__number">{{ order.payment.orderTotalPoints }}</p>
			<p class="order__points__text">pontos</p>
		</div>
	</div>
</li>

<app-modal [id]="modalCancelId" top="35px" [preventOutsideClickClose]="true">
	<app-modal-cancel
		[id]="modalCancelId"
		template="reservation"
		*ngIf="{ loading: loadingCancel$ | async } as obj"
		(cancelSubmitted)="cancelOrder()"
		[loading]="obj.loading"
	></app-modal-cancel>
</app-modal>
