<div class="modalContent">
	<div class="modalContent__header">
		<h2 class="modalContent__title">Horários de funcionamento</h2>
		<div class="modalContent__close" (click)="modalService.close('modal-schedules')">
			<i class="modalContent__close__icon fa fa-close"></i>
		</div>
	</div>
	<div class="modalContent__body">
		<ul class="modalContent__list">
			<li class="modalContent__list__item" *ngFor="let operationHour of operationHoursByTime">
				<p class="modalContent__text">
					<span class="modalContent__text--strong">{{ getWeekday(operationHour.day) }}: </span>
					&nbsp;{{ operationHour.times }}
				</p>
			</li>
		</ul>
	</div>
</div>
