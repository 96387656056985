import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core'

type Template = 'default' | 'tip' | 'points' | 'totalSum'

@Component({
	selector: 'app-info',
	templateUrl: 'info.component.html',
	styleUrls: ['./info.component.scss'],
})
export class InfoComponent implements OnInit {
	@Input() title!: string
	@Input() text!: string | null
	@Input() template: Template = 'default'
	@Output() clickedIcon = new EventEmitter()

	templates: Record<Template, boolean> = {
		default: false,
		tip: false,
		points: false,
		totalSum: false,
	}

	constructor() {}

	ngOnInit(): void {
		this.templates[this.template] = true
	}

	clickIcon() {
		this.clickedIcon.emit()
	}

	@HostBinding('style')
	get addProps() {
		if (this.template === 'points') {
			return {
				'--color-text': 'var(--green)',
				'--weight-text': '700',
			}
		}
		if (this.template === 'totalSum') {
			return {
				'--size-text': '26px',
			}
		}
		return {
			'--weight-text': '600',
		}
	}
}
