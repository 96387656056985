import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, of, tap } from 'rxjs'
import { environment } from 'src/environments/environment'

@Injectable({ providedIn: 'root' })
export class campaignService {
	constructor(private readonly http: HttpClient) {}
	private campaignSubject = new BehaviorSubject<CampaignResponse | null>(null)
	public campaign$ = this.campaignSubject.asObservable()

	private promoSubject = new BehaviorSubject<{ isPromo: boolean }>({ isPromo: false })
	promo$ = this.promoSubject.asObservable()

	isActive(reservationDate: Date, name?: string) {
		const query = new URLSearchParams({
			partnerId: '63600bcb4a49e96746e30f7d',
			reservationDate: reservationDate.toISOString(),
			...(name && { name }),
		}).toString()
		return this.http
			.get<CampaignResponse>(`${environment.channelsAPI.baseUrl}/api/v1/campaign/isActive?${query}`)
			.pipe(
				tap(campaign => this.campaignSubject.next(campaign)),
				tap(campaign => this.promoSubject.next({ isPromo: Boolean(campaign) }))
			)
	}
}

export interface CampaignResponse {
	_id: string
	partnerId: string
	startDate: string
	endDate: string
	name: string
	description: string
	value: number
	type: 'SUBSTITUTION' | 'MULTIPLIER'
	banner: {
		desktop: string
		mobile: string
	}
}
