<div class="page">
	<div class="page__header">
		<div class="wallet__header wrapper">
			<app-header></app-header>
			<h1 class="wallet__subtitle subtitle">Carteira</h1>
			<!-- <app-loading></app-loading> -->
		</div>
	</div>

	<div class="page__body">
		<div class="wallet__wrapper wrapper">
			<h2 class="wallet__titleLight titleLight">Cartões de crédito</h2>

			<div class="wallet__box" *ngIf="cards$ | async as cards">
				<app-box text="registerCard" *ngIf="cards.length === 0"></app-box>
			</div>

			<ul class="wallet__list" *ngIf="cards$ | async as cards">
				<li class="wallet__list__item" *ngFor="let card of cards">
					<div class="wallet__list__edit">
						<ul class="wallet__sublist">
							<li
								class="wallet__sublist__subitem"
								(click)="openModalMainCard(card.lastFourDigits)"
							>
								<i class="wallet__sublist__subitem__icon fa fa-star-outline"></i>
								<p class="wallet__sublist__subitem__text">Principal</p>
							</li>
							<app-modal id="{{ modals.mainCard }}_{{ card.lastFourDigits }}" top="35px">
								<app-modal-main-card
									id="{{ modals.mainCard }}_{{ card.lastFourDigits }}"
									[lastFourDigits]="card.lastFourDigits"
									(mainCardSubmitted)="updateMainCard(card.lastFourDigits)"
								></app-modal-main-card>
							</app-modal>
							<!-- <li class="wallet__sublist__subitem">
								<i class="wallet__sublist__subitem__icon fa fa-edit"></i>
								<p class="wallet__sublist__subitem__text">Editar</p>
							</li> -->
							<li
								class="wallet__sublist__subitem"
								(click)="openModalCancel(card.lastFourDigits)"
							>
								<i class="wallet__sublist__subitem__icon fa fa-delete"></i>
								<p class="wallet__sublist__subitem__text">Excluir</p>
							</li>
						</ul>
						<app-modal id="{{ modals.cancel }}_{{ card.lastFourDigits }}" top="35px">
							<app-modal-cancel
								id="{{ modals.cancel }}_{{ card.lastFourDigits }}"
								template="wallet"
								(cancelSubmitted)="removeCard(card.lastFourDigits)"
							></app-modal-cancel>
						</app-modal>
					</div>
					<div
						class="wallet__list__moving"
						[ngClass]="{ 'wallet__list__moving--click': getIsOpenCard(card.lastFourDigits) }"
						(click)="toggleOpenCard(card.lastFourDigits)"
					>
						<!-- adicionar a class CLICK para abrir a opção de edição -->
						<div class="wallet__left">
							<div class="wallet__cardFlag" *ngIf="chooseBrand(card.lastFourDigits) as brand">
								<img src="/assets/images/{{ brand.src }}" [alt]="brand.alt" />
							</div>
							<div class="wallet__favorite" *ngIf="card.mainCard">
								<i class="wallet__icon fa fa-star"></i>
							</div>
						</div>
						<div class="wallet__right">
							<div class="wallet__options">
								<i class="wallet__icon fa fa-options"></i>
							</div>
							<div class="wallet__cardNumber">
								<i class="wallet__icon fa fa-bullets"></i>
								<p class="wallet__cardNumber__theLast">{{ card.lastFourDigits }}</p>
							</div>
						</div>
					</div>
				</li>
			</ul>

			<button class="wallet__button" [routerLink]="['/carteira/adicionar-cartao']">
				<p class="wallet__button__text">Adicionar novo cartão</p>
			</button>
		</div>
	</div>

	<div class="page__footer">
		<div class="wallet__wrapper wrapper">
			<button class="wallet__buttonLight buttonLight" [routerLink]="['/minhas-reservas']">
				Ver minhas reservas
			</button>
		</div>
	</div>
</div>

<app-modal [id]="modals.failure" top="35px">
	<app-modal-failure
		[id]="modals.failure"
		template="somethingWentWrong"
		*ngIf="error$ | async as error"
		[message]="error"
	></app-modal-failure>
</app-modal>
