<div class="page">
	<div class="page__header">
		<div class="hiw__wrapper wrapper">
			<h2 class="hiw__title title">{{ pageTitle }}</h2>
		</div>
	</div>
	<div class="page__body">
		<div class="hiw__subtitle subtitle">
			Tenha uma <br />
			experiência incrível <br />
			nos melhores restaurantes
		</div>

		<div class="hiw__wrapper wrapper">
			<ul class="hiw__list">
				<li class="hiw__list__item" *ngFor="let item of instructions">
					<span class="hiw__list__item__number">{{ item.number }}</span>
					<p class="hiw__list__item__text">
						<span class="hiw__list__item__text--strong"> {{ item.title }}</span
						><br />
						{{ item.text }}
					</p>
				</li>
			</ul>

			<app-box text="rememberPoints"></app-box>
		</div>
	</div>

	<div class="page__footer">
		<div class="hiw__wrapper wrapper" [routerLink]="['/restaurantes']">
			<button class="hiw__button button">Ver restaurantes próximos</button>
		</div>
	</div>
</div>
