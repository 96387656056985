<div id="addedCard" class="modalContent">
	<div class="modalContent__header">
		<i class="modalContent__icon fa fa-check-inverse"></i>
		<h2 class="modalContent__title">Cartão cadastrado!</h2>
	</div>
	<div class="modalContent__body">
		<p class="modalContent__text">
			Agora você pode utilizá-lo para pagar a conta no restaurante e ganhar pontos!
		</p>
	</div>
	<div class="modalContent__footer">
		<button class="modalContent__button button" (click)="lastPage()">OK</button>
	</div>
</div>
