import { Component, Input, OnInit } from '@angular/core'

type BoxTemplate =
	| 'registerCard'
	| 'validationCard'
	| 'rememberPoints'
	| 'reservationDisabled'
	| 'adviceNoReservation'
	| 'noVenue'
	| 'enableCheckin'
	| 'unavailableCheckin'

@Component({
	selector: 'app-box [text]',
	templateUrl: './box.component.html',
	styleUrls: ['./box.component.scss'],
})
export class BoxComponent implements OnInit {
	@Input() type: 'reminder' | 'success' | 'advice' = 'reminder'
	@Input() text!: BoxTemplate

	templates: Record<BoxTemplate, any> = {
		registerCard: false,
		validationCard: false,
		rememberPoints: false,
		reservationDisabled: false,
		adviceNoReservation: false,
		noVenue: false,
		enableCheckin: false,
		unavailableCheckin: false,
	}

	constructor() {}

	ngOnInit(): void {
		this.templates[this.text] = true
	}
}
