import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Title } from '@angular/platform-browser'
import { Router } from '@angular/router'
import { Actions, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { combineLatest, filter, map, Subscription, switchMap, tap } from 'rxjs'
import { ModalService } from 'src/app/component/modal/modal.service'
import { CreditCardHelper } from 'src/app/shared/helpers/credit-card.helper'
import { PREFIX_TITLE } from 'src/app/shared/helpers/prefix-title'
import { State } from 'src/app/state/app.state'
import { getClient } from 'src/app/state/client/client.reducers'
import {
	getErrorPayment,
	getPaymentOrder,
	getSelectedOrder,
	loadingPayment,
} from 'src/app/state/order/order.reducers'
import * as OrderActions from '../../../state/order/order.actions'

@Component({
	templateUrl: './payment-card.component.html',
	styleUrls: ['./payment-card.component.scss'],
})
export class PaymentCardComponent implements OnInit, OnDestroy, AfterViewInit {
	// Id
	modalChangeCardId = 'modalChangeCardId'
	modalFailureId = 'modalPaymentFailureId'

	chooseBrand = CreditCardHelper.chooseBrand

	// Observables Store
	selectedOrder$ = this.store.select(getSelectedOrder).pipe(
		filter(value => Boolean(value)),
		tap(order => {
			this.title.setTitle(`${PREFIX_TITLE} - ${order?.venue.name} - Confirmar pagamento`)
		})
	)
	paymentOrder$ = this.store.select(getPaymentOrder)
	clientState$ = this.store.select(getClient)
	loadingPayment$ = this.store.select(loadingPayment)
	errorFromPayment$ = this.store.select(getErrorPayment)

	vm$ = combineLatest([this.selectedOrder$, this.paymentOrder$, this.clientState$]).pipe(
		map(([selectedOrder, paymentOrder, clientState]) => ({ selectedOrder, paymentOrder, clientState }))
	)

	// Form
	form!: FormGroup

	// Subs
	subs = new Subscription()
	constructor(
		private modalService: ModalService,
		private fb: FormBuilder,
		private store: Store<State>,
		private actions$: Actions,
		private router: Router,
		private title: Title
	) {}

	ngOnInit(): void {
		this.form = this.fb.group({
			dynamicCVV: ['', Validators.required],
		})
		this.subs.add(this.paymentSuccessAction)
	}

	ngAfterViewInit(): void {
		this.subs.add(this.paymentFailureAction)
	}

	private get paymentSuccessAction() {
		return this.actions$
			.pipe(
				ofType(OrderActions.createPaymentSuccess),
				switchMap(() => this.selectedOrder$)
			)
			.subscribe(selectedOrder =>
				this.router.navigate(['pagamento-cartao', selectedOrder?._id, 'sucesso'])
			)
	}

	private get paymentFailureAction() {
		return this.actions$
			.pipe(ofType(OrderActions.createPaymentFailure))
			.subscribe(() => this.modalService.open(this.modalFailureId))
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe()
	}

	createPayment() {
		this.store.dispatch(
			OrderActions.createPayment({ createPaymentAction: { dynamicCVV: this.form.value.dynamicCVV } })
		)
	}

	openModal() {
		this.modalService.open(this.modalChangeCardId)
	}
}
