import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
	name: 'absolutePath',
})
export class AbsolutePipe implements PipeTransform {
	private BASE_URL = 'https://tagmepub.azureedge.net'
	private PUB_IMG = this.BASE_URL + '/pubimg/'

	transform(relativePath: string | null | undefined) {
		return `${this.PUB_IMG}${relativePath}`.trim()
	}
}
