<div class="page">
	<div class="page__header">
		<div class="venues__wrapper wrapper">
			<section class="venues__section section">
				<app-header></app-header>
				<h2 class="venues__title subtitle">{{ pageTitle }}</h2>

				<!-- FILTROS -->
				<a class="action" (click)="toggleOpenFilter()">
					<i class="action__icon fa fa-filter"></i>
					<p class="action__text">Filtros</p>
				</a>
			</section>

			<div class="venues__filter" [ngClass]="{ 'venues__filter--opened ': isOpenFilter }">
				<ch-select-box
					*ngIf="cuisines$ | async as cuisines"
					[items]="cuisines"
					[selectedItem]="selectedCuisine$ | async"
					(itemWasSelected)="cuisineSelected($event)"
				></ch-select-box>
				<button class="venues__filter__buttonGhost buttonGhost" (click)="cleanFilter()">
					Limpar filtros
				</button>
			</div>

			<section class="venues__section section">
				<div class="venues__section__max-width">
					<ch-select-box
						*ngIf="addresses$ | async as addresses"
						icon="fa-localizacao"
						[items]="addresses"
						[selectedItem]="selectedAddress$ | async"
						(itemWasSelected)="addressSelected($event)"
						option="persist"
					></ch-select-box>
				</div>

				<div class="action" (click)="openModalSearch()">
					<i class="action__icon fa fa-search"></i>
					<p class="action__text">Buscar</p>
				</div>
			</section>
		</div>
	</div>

	<ng-container *ngIf="campaignService.campaign$ | async as campaign">
		<div class="banner" *ngIf="campaign.banner.mobile">
			<img class="banner__img" [src]="campaign.banner.mobile" />
			<!-- <p>
				*Oferta válida para pagamentos realizados até 17/03. Imagens meramente ilustrativas. <br />
				Consulte termos e condições em
				<a href="https://esfera.com.vc/termos-e-condicoes">esfera.com.vc/termos-e-condicoes</a>
			</p> -->
		</div>
	</ng-container>

	<div *ngIf="hasPaymentReady$ | async" style="margin: 25px">
		<app-order-card *ngFor="let order of ordersReady$ | async" [order]="order"></app-order-card>
	</div>

	<div class="page__body">
		<div class="venues__wrapper wrapper">
			<div *ngIf="errorMessage$ | async as errorMessage">
				{{ errorMessage }}
			</div>
			<div class="loading" *ngIf="isLoadingVenues$ | async">
				<app-spinner></app-spinner>
			</div>
			<ng-container *ngIf="venues$ | async as venues">
				<app-venue-card *ngFor="let venue of venues" [venue]="venue"></app-venue-card>
				<app-box text="noVenue" type="advice" *ngIf="venues.length === 0"></app-box>
			</ng-container>
			<div class="loading" *ngIf="isLoadingMoreVenues$ | async">
				<app-spinner></app-spinner>
			</div>
		</div>
	</div>

	<div class="page__footer"></div>
</div>

<app-modal [id]="modalSearchId" top="61px" animation="horizontal">
	<app-modal-search (submitted)="submitSearch($event)"></app-modal-search>
</app-modal>
