import { Pipe, PipeTransform } from '@angular/core'

type Address = {
	address: string
	neighborhood: string
	state: string
	city: string
	number: string
}

@Pipe({
	name: 'formatAddress',
})
export class FormatAddress implements PipeTransform {
	transform(address: Address | undefined) {
		if (!address) return ''
		return `${address?.address}, ${address?.number}, ${address?.neighborhood}, ${address?.city}, ${address?.state}`
	}
}
