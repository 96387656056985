// Angular/External Libraries
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserModule, Title } from '@angular/platform-browser'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { SwiperModule } from 'swiper/angular'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { NgModule } from '@angular/core'
import { NgxMaskModule } from 'ngx-mask'

// Componentes
import { NavbarComponent } from './component/navbar/navbar.component'
import { BoxComponent } from './component/box/box.component'
import { VenueCardComponent } from './component/venue-card/venue-card.component'
import { SlideComponent } from './component/slide/slide.component'
import { LoadingComponent } from './component/loading/loading.component'
import { BenefitComponent } from './component/benefit/benefit.component'
import { OrderComponent } from './component/order/order-card.component'
import { OrderVenueComponent } from './component/order/order-detail/order-detail.component'
import { TicketComponent } from './component/ticket/ticket.component'
import { HeaderComponent } from './component/header/header.component'
import { ModalHeaderComponent } from './component/header/modal-header/modal-header.component' // This ModalHeader is very different from others modal, so I put here
import { DivisorComponent } from './component/divisor/divisor.component'
import { InfoComponent } from './component/info/info.component'

// Modals
import { ModalComponent } from './component/modal/modal.component'
import { ModalSchedulesComponent } from './component/modal/modal-schedules/modal-schedules.component'
import { ModalTermsComponent } from './component/modal/modal-terms/modal-terms.component'
import { ModalCancelComponent } from './component/modal/modal-cancel/modal-cancel.component'
import { ModalRegisterCardComponent } from './component/modal/modal-register-card/modal-register-card.component'
import { ModalAddedCardComponent } from './component/modal/modal-added-card/modal-added-card.component'
import { ModalAboutComponent } from './component/modal/modal-about/modal-about.component'
import { ModalTipComponent } from './component/modal/modal-tip/modal-tip.component'
import { ModalCvvComponent } from './component/modal/modal-cvv/modal-cvv.component'
import { ModalChangeCardComponent } from './component/modal/modal-change-card/modal-change-card.component'
import { ModalSearchComponent } from './component/modal/modal-search/modal-search.component'
import { ModalFailureComponent } from './component/modal/modal-failure/modal-failure.component'

// Pages
import { HowItWorksComponent } from './page/how-it-works/how-it-works.component'
import { JourneyComponent } from './page/journey/journey.component'
import { ReservationComponent } from './page/reservation/reservation.component'
import { ReservationConfirmComponent } from './page/reservation/reservation-confirm/reservation-confirm.component'
import { ReservationFailureComponent } from './page/reservation/reservation-failure/reservation-failure.component'
import { WalletComponent } from './page/wallet/wallet.component'
import { YourCardComponent } from './page/wallet/your-card/your-card.component'
import { AddCardComponent } from './page/wallet/add-card/add-card.component'
import { OrderPageComponent } from './page/orders/order-history.component'
import { PaymentConfirmComponent } from './page/payment/payment-confirm/payment-confirm.component'
import { VenuesPageComponent } from './page/venues/venues.component'
import { VenueDetailComponent } from './page/venues/venue-detail/venue-detail.component'
import { PaymentBillComponent } from './page/payment/payment-bill/payment-bill.component'
import { PaymentCardComponent } from './page/payment/payment-card/payment-card.component'
import { CheckinAvailabilityPage } from './page/check-in/check-in-availability/check-in-availability.component'
import { CheckInConfirmPage } from './page/check-in/check-in-confirm/check-in-confirm.component'
import { TermsComponent } from './page/terms/terms.component'
import { RecommendationComponent } from './page/recommendation/recommendation.component'

// NgRx
// Reducers
import { venueReducer } from './state/venue/venue.reducers'
import { addressReducer } from './state/address/address.reducers'
import { reservationReducer } from './state/reservation/reservation.reducers'
import { orderReducer } from './state/order/order.reducers'
import { clientReducer } from './state/client/client.reducers'
import { cuisinesReducer } from './state/cuisines/cuisines.reducers'
import { checkinReducer } from './state/checkin/check-in.reducers'

// Effects
import { AddressEffects } from './state/address/address.effects'
import { VenueEffects } from './state/venue/venue.effects'
import { ReservationEffects } from './state/reservation/reservation.effects'
import { OrderEffects } from './state/order/order.effects'
import { ClientEffects } from './state/client/client.effects'
import { CuisinesEffects } from './state/cuisines/cuisines.effects'
import { CheckInEffects } from './state/checkin/check-in.effects'

// Spinner
import { SpinnerComponent } from './component/spinner/spinner.component'

// Pipes
import { AbsolutePipe } from './shared/pipes/absolute-path.pipe'
import { FormatAddress } from './shared/pipes/format-address.pipe'
import { ReplacePipe } from './shared/pipes/replace.pipe'
import { FormatPartysize } from './shared/pipes/formt-partysize.pipe'
import { FormatPhone } from './shared/pipes/format-phone.pipe'
import { CapitalizeFirstLetter } from './shared/pipes/capitalize.pipe'
import { OptimizeCard, OptimizeCardSrc } from './shared/pipes/optmize-images.pipe'

// Libs
import { TagmeChannelsComponents } from './lib/tagme-channels-components.module'

// Env
import { environment } from '../environments/environment'

// Routing
import { AppRoutingModule } from './app-routing.module'

// Root
import { AppComponent } from './app.component'

// Locale Pt
import { LOCALE_ID } from '@angular/core'
import { registerLocaleData, DATE_PIPE_DEFAULT_TIMEZONE } from '@angular/common'

import localePt from '@angular/common/locales/pt'
import { LoginComponent } from './page/login/login.component'
import { NgxCurrencyModule } from 'ngx-currency'
import { ModalMainCardComponent } from './component/modal/modal-main-card/modal-main-card.component'
registerLocaleData(localePt)

import { LottieModule } from 'ngx-lottie'
import { ReservationCancelSuccessComponent } from './page/reservation/reservation-cancel-success/reservation-cancel-sucess.component'
import { ReservationCancelEmailSuccessComponent } from './page/reservation/reservation-cancel-email-success/reservation-cancel-email-success.component'
import { ReservationCancelFailureComponent } from './page/reservation/reservation-cancel-failure/reservation-cancel-failure.component'
import { ReservationCancelEmailFailureComponent } from './page/reservation/reservation-cancel-email-failure/reservation-cancel-email-failure.component'

import player from 'lottie-web'
import { TokenInterceptor } from './shared/services/auth.interceptor'
import { CustomHeadersInterceptor } from './shared/services/custom-headers.interceptor'
import { RecommendationSuccessComponent } from './page/recommendation-success/recommendation-success.component'

export function playerFactory() {
	return player
}

@NgModule({
	declarations: [
		// Components
		NavbarComponent,
		BoxComponent,
		VenueCardComponent,
		SlideComponent,
		LoadingComponent,
		BenefitComponent,
		OrderComponent,
		OrderVenueComponent,
		TicketComponent,
		LoadingComponent,
		HeaderComponent,
		ModalHeaderComponent,
		DivisorComponent,
		InfoComponent,

		// Pages
		HowItWorksComponent,
		JourneyComponent,
		ReservationComponent,
		ReservationConfirmComponent,
		ReservationFailureComponent,
		WalletComponent,
		YourCardComponent,
		AddCardComponent,
		OrderPageComponent,
		PaymentConfirmComponent,
		VenuesPageComponent,
		VenueDetailComponent,
		LoginComponent,
		CheckinAvailabilityPage,
		CheckInConfirmPage,
		TermsComponent,
		RecommendationComponent,
		RecommendationSuccessComponent,

		// Modals
		ModalComponent,
		ModalSearchComponent,
		ModalSchedulesComponent,
		ModalTermsComponent,
		ModalCancelComponent,
		ModalRegisterCardComponent,
		ModalAddedCardComponent,
		ModalAboutComponent,
		ModalTipComponent,
		ModalCvvComponent,
		ModalChangeCardComponent,
		ModalFailureComponent,
		ModalMainCardComponent,

		// Spinner
		SpinnerComponent,

		// Pipes
		AbsolutePipe,
		FormatAddress,
		ReplacePipe,
		FormatPartysize,
		FormatPhone,
		CapitalizeFirstLetter,
		OptimizeCard,
		OptimizeCardSrc,

		// Root
		AppComponent,
		PaymentBillComponent,
		PaymentCardComponent,
		ModalSearchComponent,
		ReservationCancelSuccessComponent,
		ReservationCancelEmailSuccessComponent,
		ReservationCancelFailureComponent,
		ReservationCancelEmailFailureComponent,
	],
	imports: [
		FormsModule,
		ReactiveFormsModule,
		BrowserModule,
		HttpClientModule,
		TagmeChannelsComponents,
		NgxCurrencyModule,
		SwiperModule,
		StoreModule.forRoot(
			{
				venues: venueReducer,
				address: addressReducer,
				availability: reservationReducer,
				order: orderReducer,
				client: clientReducer,
				cuisines: cuisinesReducer,
				checkin: checkinReducer,
			},
			{}
		),
		StoreDevtoolsModule.instrument({
			name: 'Esfera App DevTools',
			maxAge: 25,
			logOnly: environment.production,
		}),
		EffectsModule.forRoot([
			VenueEffects,
			AddressEffects,
			ReservationEffects,
			OrderEffects,
			ClientEffects,
			CuisinesEffects,
			CheckInEffects,
		]),
		NgxMaskModule.forRoot(),
		LottieModule.forRoot({ player: playerFactory }),
		AppRoutingModule,
	],
	providers: [
		{ provide: LOCALE_ID, useValue: 'pt-BR' },
		Title,
		{ provide: DATE_PIPE_DEFAULT_TIMEZONE, useValue: '-0300' },
		{ provide: HTTP_INTERCEPTORS, useClass: CustomHeadersInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
