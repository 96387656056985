import { Injectable } from '@angular/core'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import * as ClientActions from './client.actions'

import { mergeMap, map, catchError, switchMap, tap } from 'rxjs/operators'
import { of } from 'rxjs'
import { Store } from '@ngrx/store'
import { State } from '../app.state'
import { ClientService } from './client.service'
import { getClient, getClientId } from './client.reducers'
import { LocalStorageService, LOCAL_KEYS } from 'src/app/shared/services/local-storage.service'
// import { getVenues } from './venue.reducers'

export const LOCAL_TOKEN = 'token'
export const LOCAL_CLIENT_ID = 'clientId'
@Injectable()
export class ClientEffects {
	constructor(
		private actions$: Actions,
		private clientService: ClientService,
		private localStorageService: LocalStorageService,
		private store: Store<State>
	) {}

	// Cached
	// getClient$ = createEffect(() => {
	// 	return this.actions$.pipe(
	// 		ofType(ClientActions.getClient),
	// 		concatLatestFrom(() => this.store.select(getClient)),
	// 		// Only load venues if the array has at least one venue
	// 		filter(([_, orders]) => !Boolean(orders?.length)),
	// 		map(() => OrderActions.loadOrderHistory({}))
	// 	)
	// })

	// Call API (not cached)
	loginClient$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(ClientActions.loginClient),
			mergeMap(action => {
				return this.clientService.loginClient(action.id).pipe(
					tap(client => {
						this.localStorageService.setTime(LOCAL_KEYS.USER, { ...client, version: '0.1' })
						localStorage.setItem(LOCAL_TOKEN, JSON.stringify(client.access_token.token))
						localStorage.setItem(LOCAL_CLIENT_ID, client._id)
					}),
					map(client => ClientActions.loginClientSuccess({ client })),
					catchError(error => {
						return of(ClientActions.loginClientFailure({ error: error?.error?.message ?? '' }))
					})
				)
			})
		)
	})

	// Login SSO
	loginClienSSO$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(ClientActions.loginClientSSO),
			mergeMap(({ esferaToken, esferaIdentifier }) => {
				return this.clientService.loginClientSSO(esferaToken, esferaIdentifier).pipe(
					tap(client => {
						this.localStorageService.setTime(LOCAL_KEYS.USER, { ...client, version: '0.1' })
						localStorage.setItem(LOCAL_TOKEN, JSON.stringify(client.access_token.token))
						localStorage.setItem(LOCAL_CLIENT_ID, client._id)
					}),
					map(client => ClientActions.loginClientSuccess({ client })),
					catchError(error => of(ClientActions.loginClientFailure({ error: error.error?.message })))
				)
			})
		)
	})

	addNewCard$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(ClientActions.addNewCard),
			concatLatestFrom(() => this.store.select(getClient)),
			mergeMap(([action, clientState]) =>
				this.clientService
					.createCardToken({
						clientId: clientState.client._id,
						cpf: action.cpf,
						cardProperties: { ...action.encryptData, billingAddress: action.billingAddress },
						...(action.mainCard ? { mainCard: action.mainCard } : {}),
					})
					.pipe(
						map((res: any) => ClientActions.addNewCardSuccess({ gatewayId: res.gatewayId })),
						catchError(error =>
							of(ClientActions.addNewCardFailure({ error: error?.error?.message }))
						)
					)
			)
		)
	})

	updateClientFront$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(ClientActions.updateClient, ClientActions.addNewCardSuccess),
			concatLatestFrom(() => this.store.select(getClientId)),
			tap(console.log),
			mergeMap(([action, clientId]) =>
				this.clientService.getClient(clientId).pipe(
					tap(client => this.localStorageService.set(LOCAL_KEYS.USER, client)),
					switchMap(client => [
						ClientActions.loginClientSuccess({ client }),
						ClientActions.setSelectedCardByGatewayId({ gatewayId: action.gatewayId }),
					]),
					catchError(error => of(ClientActions.loginClientFailure({ error: error.error.message })))
				)
			)
		)
	})

	removeCard$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(ClientActions.removeCard),
			concatLatestFrom(() => this.store.select(getClientId)),
			mergeMap(([action, clientId]) =>
				this.clientService.removeCard(clientId, action.lastFourDigits).pipe(
					map(() => ClientActions.updateClient()),
					catchError(error => of(ClientActions.loginClientFailure({ error: error.error.message })))
				)
			)
		)
	})

	updateMainCard$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(ClientActions.updateMainCard),
			concatLatestFrom(() => this.store.select(getClientId)),
			mergeMap(([action, clientId]) =>
				this.clientService.updateMainCard(clientId, action.lastFourDigits).pipe(
					switchMap(() => [ClientActions.updateClient(), ClientActions.updateMainCardSuccess()]),
					catchError(error => of(ClientActions.updateMainCardError({ error: error.error.message })))
				)
			)
		)
	})
}
