import { Location } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { ModalService } from '../modal.service'

@Component({
	selector: 'app-modal-added-card',
	templateUrl: './modal-added-card.component.html',
	styleUrls: ['./modal-added-card.component.scss', './../modal-content.component.scss'],
})
export class ModalAddedCardComponent implements OnInit {
	constructor(private router: Router, private location: Location) {}

	ngOnInit(): void {}

	lastPage() {
		this.location.back()
	}
}
