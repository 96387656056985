<!-- conteúdo para CARTÃO NÃO AUTORIZADO -->
<div id="failureMsg" class="modalContent" *ngIf="templates.cardNotAuthorized">
	<div class="modalContent__header">
		<i class="modalContent__icon fa fa-cancel-circle"></i>
		<h2 class="modalContent__title">Cartão não autorizado.</h2>
	</div>
	<div class="modalContent__body">
		<p class="modalContent__text">Por favor, entre em contato com o seu cartão ou cadastre um novo.</p>
	</div>
	<div class="modalContent__footer">
		<button class="modalContent__button button" [routerLink]="['/carteira/adicionar-cartao']">OK</button>
		<!-- ATENÇÃO! Esse botão leva para '/carteira/adicionar-cartao' -->
	</div>
</div>

<!-- conteúdo para PAGAMENTO NÃO AUTORIZADO -->
<div id="failureMsg" class="modalContent" *ngIf="templates.paymentNotApproved">
	<div class="modalContent__header">
		<i class="modalContent__icon fa fa-cancel-circle"></i>
		<h2 class="modalContent__title">Pagamento não aprovado.</h2>
	</div>
	<div class="modalContent__body">
		<p class="modalContent__text">
			O meio de pagamento, cartão Mastercard final <span class="modalContent__text--strong">0111</span>,
			foi recusado.<br />
			Para efetuar essa compra, indique outro cartão ou meio de pagamento.
		</p>
	</div>
	<div class="modalContent__footer">
		<button class="modalContent__button button" [routerLink]="['/pagamento-cartao']">OK</button>
		<!-- ATENÇÃO! Esse botão leva para 'pagamento-cartão' -->
	</div>
</div>

<!-- conteúdo para CONEXÃO PERDIDA -->
<div id="failureMsg" class="modalContent" *ngIf="templates.lostConnection">
	<div class="modalContent__header">
		<h2 class="modalContent__title">Conexão perdida.</h2>
	</div>
	<div class="modalContent__body">
		<p class="modalContent__text">
			Parece haver um problema na conexão com o servidor. Por favor tente novamente mais tarde!
		</p>
	</div>
	<div class="modalContent__footer">
		<button class="modalContent__button button" [routerLink]="['/']">OK</button>
		<!-- ATENÇÃO! Esse botão deve recarregar a página -->
	</div>
</div>

<!-- conteúdo para MENSAGEM DE ERRO -->
<div id="failureMsg" class="modalContent" *ngIf="templates.somethingWentWrongGeneric">
	<div class="modalContent__header">
		<h2 class="modalContent__title">Algo deu errado</h2>
	</div>
	<div class="modalContent__body">
		<p class="modalContent__text">Ocorreu um erro inesperado. Por favor tente novamente.</p>
	</div>
	<div class="modalContent__footer">
		<button class="modalContent__button button" [routerLink]="['/']">OK</button>
		<!-- ATENÇÃO! Esse botão deve recarregar a página -->
	</div>
</div>

<!-- conteúdo para MENSAGEM DE ERRO -->
<div id="failureMsg" class="modalContent" *ngIf="templates.somethingWentWrong">
	<div class="modalContent__header">
		<h2 class="modalContent__title">Algo deu errado</h2>
	</div>
	<div class="modalContent__body">
		<p class="modalContent__text">{{ message }}</p>
	</div>
	<div class="modalContent__footer">
		<button class="modalContent__button button" (click)="closeModalFailure()">OK</button>
	</div>
</div>
