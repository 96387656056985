import { Component, HostBinding, Input, OnInit } from '@angular/core'

type Template = 'default' | 'small'
@Component({
	selector: 'app-spinner',
	templateUrl: './spinner.component.html',
	styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
	@Input() size: Template = 'default'

	constructor() {}

	@HostBinding('style')
	get addProps() {
		const template: Record<Template, any> = {
			default: {
				'--border': '5px',
				'--size': '38px',
			},
			small: {
				'--border': '2px',
				'--size': '25px',
			},
		}
		return template[this.size]
	}
}
