import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'

@Injectable({
	providedIn: 'root',
})
export class RecommendationService {
	constructor(private http: HttpClient) {}

	sendEmail(content: string) {
		//OBSERVABLE
		const body = {
			venue: '63600bcb4a49e96746e30f7d',
			origin: 'CHANNELS',
			sender: 'noreply@tagme.com.br',
			content: content,
			subject: 'Indicação de restaurante',
			targets: [{ destination: 'canais@tagme.com.br' }],
		}
		return this.http.post(`${environment.channelsAPI.baseUrl}/communications/send`, body)
	}
}
