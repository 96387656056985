import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { HttpService } from '../../shared/services/http.service'
import { Address, AddressApi } from './address'

@Injectable({
	providedIn: 'root',
})
export class AddressService {
	constructor(private http: HttpService) {}

	getAddresses(): Observable<Address[]> {
		return this.http
			.get<AddressApi[]>(`api/v1/address`)
			.pipe(map(addressApi => [...new Set(addressApi.map(address => address._id.city.trim()))]))
	}
}
