import { Injectable } from '@angular/core'

import { HttpService } from '../../shared/services/http.service'
import { Cuisine } from './cuisines'

@Injectable({
	providedIn: 'root',
})
export class CuisinesSevice {
	constructor(private http: HttpService) {}

	getCuisines() {
		return this.http.get<Cuisine[]>(`api/v1/venues/cousines`)
	}
}
