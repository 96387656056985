import { Injectable } from '@angular/core'
import * as CryptoJS from 'crypto-js'
import { environment as env } from 'src/environments/environment'

@Injectable({
	providedIn: 'root',
})
export class CryptoService {
	constructor() {}

	encode(context: any) {
		return CryptoJS.AES.encrypt(JSON.stringify(context), env.cryptoJSKey).toString()
	}

	decode(token: string) {
		const bytes = CryptoJS.AES.decrypt(token, env.cryptoJSKey)
		if (bytes.toString()) {
			return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
		}
		return token
	}
}
