import { Component, OnInit } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { environment } from 'src/environments/environment'
import { DataLayerService } from './shared/services/gtm/data-layer.service'
import { Store } from '@ngrx/store'
import { State } from './state/app.state'
import * as ClientActions from './state/client/client.actions'
import { getClient } from './state/client/client.reducers'
import { Actions, ofType } from '@ngrx/effects'
import { take } from 'rxjs'
import { ModalService } from './component/modal/modal.service'
import { campaignService } from './shared/services/campaign.service'

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	title = 'esfera'
	env = environment
	clientId: string | null = null
	clientState$ = this.store.select(getClient)
	modalLoginError = 'modalLoginErrorId'

	constructor(
		private router: Router,
		private dataLayerService: DataLayerService,
		private store: Store<State>,
		private actions$: Actions,
		private modalService: ModalService,
		private campaignService: campaignService
	) {
		this.router.events.subscribe(event => {
			// subscribe to router events
			if (event instanceof NavigationEnd) {
				// if our event is of our interest
				this.dataLayerService.logPageView(event.url) //call our dataLayer service's page view method to ping home with the url value.
			}
		})
		this.campaignService.isActive(new Date()).pipe(take(1)).subscribe()
	}

	ngOnInit(): void {
		this.actions$.pipe(ofType(ClientActions.loginClientSuccess), take(1)).subscribe(() => {
			this.router.navigate(['/restaurantes'])
		})

		this.actions$.pipe(ofType(ClientActions.loginClientFailure)).subscribe(({ error }) => {
			if (error.includes('desabilitado')) {
				this.modalService.open(this.modalLoginError)
			}
		})
	}

	login() {
		if (this.clientId) {
			this.store.dispatch(ClientActions.loginClient({ id: this.clientId }))
		}
	}
}
