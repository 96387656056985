import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { map, Observable, Subscription, tap } from 'rxjs'
import { ModalService } from '../modal.service'

@Component({
	selector: 'app-modal-tip',
	templateUrl: './modal-tip.component.html',
	styleUrls: ['./modal-tip.component.scss', './../modal-content.component.scss'],
})
export class ModalTipComponent implements OnInit, OnDestroy {
	@Input() id!: string
	@Input() suggestedTip!: number
	@Input() billTip!: number | null
	@Output() changedTip = new EventEmitter<number>()

	formTip!: FormGroup
	hasTipChanged$!: Observable<boolean>
	subs = new Subscription()

	constructor(private modalService: ModalService, private fb: FormBuilder) {}

	ngOnInit(): void {
		this.formTip = this.fb.group({
			tip: [this.suggestedTip],
		})

		this.subs.add(
			this.formTip.get('tip')?.valueChanges.subscribe(tip => {
				this.changedTip.emit(tip)
			})
		)

		this.hasTipChanged$ = this.formTip.valueChanges.pipe(map(form => form.tip !== this.suggestedTip))
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe()
	}

	closeModal() {
		this.modalService.close(this.id)
	}

	changeTip() {
		this.changedTip.emit(this.formTip.value.tip)
		this.modalService.close(this.id)
	}
}
